:root body {
	.no-format {list-style:none; padding:0; margin:0}
	.ib {display:inline-block;*display:inline;zoom:1}
	.indent {text-transform:capitalize; text-align:left; text-indent:-99999px}
	.rel {position:relative}
	.box {-webkit-box-sizing: border-box;-moz-box-sizing: border-box; box-sizing: border-box}
	.f-left{float:left;}
	.f-right{float:right;}
	.thumbnail{
	  background-size:cover;
	  background-position:center center;
	  background-repeat:no-repeat;;
	}
	.text-align{
	  &.center{text-align:center}
	  &.left{text-align:left}
	  &.right{text-align:right;}
	}
	.table{width: 100%;height: 100%;display: table;}
	.cell{
	  display:table-cell;width:100%;
	  &.middle{vertical-align:middle;}
	  &.bottom{vertical-align:bottom;}
	  &.top{vertical-align:top;}
	}
	.page-with-sidebar,
	.page-without-sidebar,
	.page-accordions,
	.page-maps{
		margin-top: 45px;

		@media (max-width: 1000px){
			margin-top: 0;
		}

		h1, h2, h3, h4, h5, h6, p, ol, ul{
			&.nmb{
				margin-bottom: 10px !important;
			}
		}
		.full,
		.column-full{
			background-color: transparent;
			width: 100% !important;
			padding-left:0;
			width: 100% !important;
			padding:0 !important;
			margin: 0 !important;
			background-color: transparent !important;
			.column-full .btn{
				&.job-posting{
					width: calc(100% - 50px);
					margin-left: auto;
					margin-right: auto;
					display: block;
					margin-bottom: 20px;
				}
			}
		}
		.btn.btn{
			width: 100%;
			box-sizing:border-box;
			background-size:auto 50%;
			background-size: 12.02px 14.586px;
			background-position: top 50% right 13px;
			font-size:16px;

			&.light,
			&.job-posting{
				background-color:#E4D6DE;
				color:#231F20;
				background-image:url(../images/btn-light/chevron.svg);
				&:hover{
					color:$white;
					background-color:#8F3D71;
					background-image:url(../images/button-arrow.svg);
				}
			}
			&.green{

				color:$white;
				background-color:#48A088;
				&:hover{
					color:$white;
					background-color:#8F3D71;
				}
			}
		}
		.title{
			font-size: 48px;
			font-weight: 900;
			text-transform: none !important;
			margin-bottom: 40px !important;
			line-height: 1.2 !important;

			@media (max-width: $b3){
				font-size: 28px;
			}
		}
		.telephone.no-icon{
			background:none !important;
			padding-left: 0 !important;
		}
		.hero{
			background-size:cover;
			background-position:center center;
			background-repeat:no-repeat;
			height: 350px;
			width: 100%;
			display: block;
			margin-bottom: 40px;
			border-radius:50px;
			overflow: hidden;
			@media only screen and (max-width: 767px){
				border-radius:25px;
				height: 250px;
			}
			.container{
				text-align: center;
				*:last-child{
					margin-bottom: 0 !important;
				}
				h3{
					@include inline;
					-webkit-box-shadow: 0px 0px 40px 0px rgba(255,255,255,0.75);
					-moz-box-shadow: 0px 0px 40px 0px rgba(255,255,255,0.75);
					box-shadow: 0px 0px 40px 0px rgba(255,255,255,0.75);
					text-shadow: 0px 0px 40px #ffffff;
					background-color:rgba(255,255,255,0.4);
					color:#8F3D71;
					border-radius:999px;
				}
			}
		}
		ul,
		ol{
			&.awards{
				width: 100% !important;
				max-width: 100% !important;
				margin:40px auto !important;
				padding:0 !important;
				font-size: 0;
				line-height: 0;
				li{
					width: 16.6666%;
					vertical-align: middle;
					box-sizing:border-box;
					padding:0 0 10px;
					@media only screen and (max-width: 900px){
						width: 33.333% !important;
					}

					@media only screen and (max-width: 500px){
						width: 50% !important;
					}
					img{
						max-width: 95%;
						display: block;
						margin:0 auto;
					}
				}
			}
		}
		p,
		li{
			font-size: 18px;
			line-height: 1.2;
			&.larger{
				font-size: 20px;

				@media (max-width: $b3){
					font-size: 16px;

					a{
						word-break: break-word;
					}
				}
			}
		}
		p{
			font-weight: 500;
			&.nmb{
				margin-bottom: 10px !important;
			}
		}
		.size-full img,
		img.size-full{
			width:auto;
			margin-left:auto;
			margin-right:auto;
			height: auto;
			max-width: 800px;
			display: block;
			&.full-width{
				width: 100%;
			}
		}
		h1,h2,h3,h4,h5,h6{
			text-transform: uppercase;
		}
		h1,h2,h3,h4,h5,h6,p{
			display: block;
			width: 100%;
			float: none;
		}
		blockquote{
			display: block;
			box-sizing:border-box;
			margin:30px auto 30px;
			width: 100%;
			padding:30px;
			border:2px solid $white;
			border-radius:50px;
			color:$white;
			font-style:normal;
			background-color:#8F3D71;
			*{
				color:$white;
				font-style:normal;
				&:last-child{
					margin-bottom: 0;
				}
			}
			@media only screen and (max-width: 767px){
				border-radius:25px;
			}
		}
		.half-content-width{
			font-size: 0;
			line-height: 0;
			max-width: 880px;
			display: block;
			clear: both;
			margin-bottom: 30px;
			li{
				display: block;
				float: left;
				box-sizing:border-box;
				margin-bottom: 12px;
				width:100%;

				@media only screen and (min-width: 768px){
					width: 50%;
					&:nth-child(odd){
						padding-right:10px;
					}
					&:nth-child(even){
						padding-left:10px;
					}
				}
			}
		}
		.non-float-social-icons .ib{
			font-size: 0;
			line-height: 0;
		}
	}

	.page-with-sidebar{
		.full{
			background-color: transparent;
			width: 100% !important;
			padding-left:0;
			p{
				font-size: 18px;
				line-height: 1.2;
				display: block;
			}
			.column-one{
				width: 41%;
				margin-right:0;
				padding-left:40px;
				p{
					max-width: 380px;
				}
			}
			.column-two{
				width: 55%;
			}
			@media only screen and (max-width: 767px){
				.column-two,
				.column-one{
					width:100%;
					float: none;
				}
			}
		}
	}
	.page-maps{
		.locations{
			max-width: 800px;
			margin:0 auto !important;
			.location{
				width: 50%;
				display: block;
				float: left;
				box-sizing:border-box;
				padding:0 20px 20px;
				@media only screen and (max-width:767px){
					padding:0 0 20px;
					width: 100%;
					float: none;
					display: block;
				}
				.map,
				.text{
					display: block;
					width: 100%;
					max-width: 340px;
					margin-left: auto;
					margin-right: auto;
					@media only screen and (max-width:767px){
						width: 100%;
						max-width: 100%;
					}
					a{
						color:$white !important;
						&:hover{
							color:$purple !important;
						}
					}
					h2{
						font-size: 26px;
					}
					p{
						font-size: 18px;
					}
				}
				.map{
					margin-bottom: 30px;
					@media only screen and (max-width:767px){
						height: auto;
						padding-bottom:80%;
					}
				}
			}
		}
	}
	.page-accordions{
		.accordions{
			width: calc(100% - 50px);
			margin-left:auto;
			margin-right: auto;
			margin-top: 40px;
			@media only screen and (max-width: 767px){
				width: 100%;
			}
			&--title{
				margin-bottom: 15px;
				a{
					background-image:none;
					position:relative;
					background-image:none !important;
					&::before{
						content:'';
						display: block;
						position:absolute;
						top:50%;
						right: 13px;
						background-size:contain;
						background-repeat:no-repeat;
						background-position: center center;
						transform-origin:top;
						background-image:url(../images/btn-light/chevron.svg);
						width: 17.5px;
						height: 17.5px;
						transform:translateY(-50%);
					}
					&:hover{
						background-image:none !important;
					}
					&.active{
						color:$white !important;
						background-color:#8F3D71 !important;
						&::before{
							background-image:url(../images/button-arrow.svg);
							transform:rotate(90deg) translateY(-50%);
						}
					}
					&:hover{
						color:$white !important;
						background-color:#8F3D71 !important;
						&::before{
							background-image:url(../images/button-arrow.svg);
						}
					}
				}
			}
			&--content{
				padding-bottom: 20px;
				width: 100%;
				box-sizing: border-box;
				padding-left:0;
				padding-right:0;

				ul,
				ol{
					margin-left: 0 !important;
				}
				.half-content-width{
					padding-left:0;
					font-size: 0;
					font-size: 0;
					line-height: 0;
					max-width: 100% !important;
					display: block;
					clear: both;
					margin-top: 30px;
					margin-bottom: 30px;
					padding-top:25px;
					padding-bottom:25px;
					border-top:2px solid $white;
					border-bottom:2px solid $white;
					padding-left:0;
					margin-left: 0;
					li{
						display: inline-block;
						box-sizing:border-box;
						margin-bottom: 12px;
						width:100%;
						font-size: 18px;
						padding-right:20px;
						padding-left:25px;
						position:relative;
						float: none;
						vertical-align: top;
						&::before{
							content:'';
							position:absolute;
							top:9px;
							left:0;
							width: 6px;
							height: 6px;
							display: block;
							background-color:$white;
							border-radius:999px;
						}
						&.larger{
							font-size: 20px;
						}
						@media only screen and (min-width: 768px){
							width: 50%;
						}
					}
				}
				p:last-child{
					margin-bottom: 0;
				}
				.btn{
					text-transform: none !important;
					font-size:18px;
					margin-bottom: 40px;
				}
			}
		}
	}
	.page-without-sidebar{}
	.wpcf7-form{
		input[type="text"],
		input[type="password"],
		input[type="email"],
		input[type="number"],
		input[type="date"],
		input[type="search"],
		input[type="tel"],
		input[type="url"],
		select{
			height: 40px !important;
			line-height: 34px !important;
			font-size: 16px !important;
			box-sizing:border-box;
			border:2px solid white;
			color:black;
			padding:0 20px;
			border-radius:9999px;
			font-weight: bold;
			background-color:#E4D6DE;
			width: 100%;
			margin-bottom: 0 !important;
			&:placeholder{
				font-weight: bold;
				text-transform: uppercase;
				color:black;
			}
			&::placeholder{
				font-weight: bold;
				text-transform: uppercase;
				color:black;
			}
		}
		textarea{
			line-height: 1.6;
			font-size: 16px;
			box-sizing:border-box;
			border:2px solid white;
			color:black;
			box-sizing:border-box;
			padding:20px;
			border-radius:20px;
			font-weight: bold;
			background-color:#E4D6DE;
			margin-bottom: 0 !important;
			&:placeholder{
				font-weight: bold;
				text-transform: uppercase;
				color:black;
			}
			&::placeholder{
				font-weight: bold;
				text-transform: uppercase;
				color:black;
			}
		}
		button,
		input[type="submit"]{
			height: 40px !important;
			line-height: 34px !important;
			font-size: 16px !important;
			box-sizing:border-box;
			border:2px solid white;
			color:white;
			text-shadow: none;
			padding:0 20px;
			border-radius:9999px;
			font-weight: bold;
			background-color:#48A088;
			width: 100%;
			margin-bottom: 0 !important;
			text-transform: uppercase;;
			background-image: url(../images/button-arrow.svg);
			background-repeat: no-repeat;
			background-position: top 50% right 10px;

			&:hover,
			&:focus{
				background-color: $purple;
			}
		}
	}
}

span.wpcf7-not-valid-tip{
	padding-top: 5px;
}

.page-template-tmp-page-accordions{

	.accordions--title .btn{
		text-transform: none;
	}
}

.page-id-6006{

	.column-full{

		p{

			a{
				color: white;
				text-decoration: underline;

				&:hover,
				&:focus{
					color: $purple;
				}
			}
		}
	}
}


.page-template-tmp-page-sidebar{

	.hero{

		.middle{

			h3{
				text-transform: uppercase!important;
			}
		}
	}
}

textarea{
	overflow: auto;
}

//.sf-level-0.drop-down{
//	cursor: pointer;
//
//	> .sf-input-checkbox{
//
//		&:checked{
//
//			~ .sf-label-checkbox{
//				pointer-events: auto;
//			}
//
//			~ .children{
//				max-height: 9999px;
//			}
//		}
//	}
//
//	&.open{
//
//		> .sf-label-checkbox{
//			pointer-events: auto;
//		}
//
//		.children{
//			max-height: 9999px;
//		}
//	}
//
//	> .sf-label-checkbox{
//		pointer-events: none;
//	}
//
//	.children{
//		max-height: 0;
//		overflow: hidden;
//	}
//}
//
//.sf-level-0{
//
//	> .sf-input-checkbox{
//
//		&:checked{
//
//			~ .sf-label-checkbox{
//				pointer-events: auto;
//			}
//
//			~ .children{
//				max-height: 9999px;
//			}
//		}
//	}
//
//	.children{
//		max-height: 0;
//		overflow: hidden;
//	}
//}

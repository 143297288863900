header > .container {position: relative;}
div.slide-down {
	width: 100%;
	position: absolute;
	top: 48px;
	right: 0;
	z-index: 300;
}

header{
	z-index: 111;
	position: relative;
}

section.menu,
section.search { display: none; }
section.menu {

}

section.menu section h2, section.search section h2 {
	width: 100%;
	margin: 0;
	text-align: center;
	font-size: 24px;
	color: #fff;
}

section.search {
	float: right;
	padding: 12px;
	background-color: #782a50;
	width: 550px;
}

section.search form {margin: 0;}

section.search form h2 {
	margin-bottom: 32px;
	padding: 0;
	text-align: center;
}

section.search form input {
	float: left;
	margin: 0;
}

section.search form input[type="text"] {
	background: #fff;
	width: 525px;
	height: 54px;
	border: none;
	line-height: 54px;
	font-size: 14px;
}
.search-results .sidebar-inner {display: none;}
section.search form input[type="submit"] {
	width: 126px;
	height: 54px;
	font-size: 16px;
	line-height: 54px;
	-webkit-box-shadow: -3px 3px 0 rgba(0,0,0,0.25);
	-moz-box-shadow: -3px 3px 0 rgba(0,0,0,0.25);
	-o-box-shadow: -3px 3px 0 rgba(0,0,0,0.25);
	box-shadow: -3px 3px 0 rgba(0,0,0,0.25);
	-webkit-text-shadow: 2px 2px 1px rgba(0,0,0,0.65);
	-moz-text-shadow: 2px 2px 1px rgba(0,0,0,0.65);
	-o-text-shadow: 2px 2px 1px rgba(0,0,0,0.65);
	text-shadow: 2px 2px 1px rgba(0,0,0,0.65);
}

section.menu div.container { padding: 36px 0 100px; }
section.menu h2 {
	margin-left: 280px;
	padding: 0;
	text-align: left;
	line-height: 36px;
}

section.menu ul {
	margin: 0;
	list-style-type: none;
}
.archive.post-type-archive-media .sidebar-inner {
display: none !important;
}

section.menu ul li a,
section.menu ul li a:hover { color: #fff; }
section.menu div.columns > ul {
	width: 184px;
	margin-top: 0;
	margin-right: 52px;
}

section.menu div.columns > ul > li { margin-top: 40px; }
section.menu div.columns > ul > li.amazon a {
	background: url('../images/buy-from-amazon.png') bottom left no-repeat;
	padding-bottom: 75px;
}

section.menu div.columns > ul > li a {
	display: block;
	background: url('../images/nav-column-title-background.png') bottom left repeat-x;
	margin-bottom: 16px;
	padding-bottom: 20px;
	font-size: 22px;
}

section.menu div.columns > ul > li ul li a {
	background: none;
	margin: 0;
	padding: 0;
	font-size: 15px;
	line-height: 18px;
}

div.topbar { width: 100%; }
div.topbar div.inner-bar {
	width: 940px;
	margin: 0 auto;
	position: relative;
}

header > .container {}
.main-nav {
	float: right;
}

.main-nav ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
	float: left;

	&.menu > li {
		float: left;
		position: relative;

		&:last-of-type{

			.sub-menu{
				right: 0;
				left: auto;
			}
		}

		&:not(.menu-item-has-children){

			&:hover,
			&:focus{

				> a{
					background-color: $green!important;

					&:after{
						background-image: url(../images/menu-arrow-green.svg);
						display: block;
					}
				}
			}
		}

		&:hover,
		&:focus{

			> a{
				background-color: $green!important;

				&:after{
					display: none;
				}
			}

			.sub-menu.sub-menu{
				display: block!important;
			}
		}

		&:nth-of-type(2n + 1){

			> a{
				background-color: $purple;

				&:after{
					background-image: url(../images/menu-arrow.svg);
				}
			}
		}

		> a{
			display: block;
			min-width: 100px;
			text-align: center;
			padding: 10px 10px;
			background-color: $purple-light;
			position: relative;
			min-width: 125px;

			@media (max-width: 1150px){
				min-width: 100px;
			}

			&:hover,
			&:focus{
				background-color: $green;

				&:after{
					background-image: url(../images/menu-arrow-green.svg);
				}
			}

			&:after{
				content: '';
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				height: 18px;
				background-size: 100% 100%;
				background-image: url(../images/menu-arrow-light.svg);
				background-repeat: no-repeat;
			}
		}
	}

	li{

		a {
			color: #fff;
			font-size: 16px;
			line-height: 19px;
			text-transform: capitalize;
		}

		.sub-menu{
			background-color: $green;
			position: absolute;
			top: 100%;
			left: 0;
			right: auto;
			z-index: 11;
			z-index: -1;
			//padding: 22px 22px 15px;
			width: 120%;
			display: none!important;
			padding: 10px 0 10px;
			min-width: 218px;

			li {
				width: 100%;
				display: list-item;
				padding: 0;
				margin: 0;

				a{
					font-size: 14px;
					text-transform: none;
					padding: 5px 22px;
					padding: 0.7px 14px;
					padding: 0.7px 23px;
					display: block;
					text-transform: none;

					&:hover,
					&:focus{
						background-color: lighten($green, 10%);
						color: white;
					}
				}
			}
		}
	}
}




//
////.main-nav ul li#menu-item-4551 {padding: 11px 39px;}
//.main-nav .menu > li:nth-child(2n) {background-color: #782a50;}
//.main-nav .menu > li:nth-child(2n) a::after {content:""; background-image: url(../images/nav-down-1.png); position: absolute; bottom: -19px; width: 100%; left: 0; right: 0; height: 19px; z-index: 10; background-repeat: no-repeat; background-position: center;}
//.main-nav .menu > li:nth-child(3) a::after {content:""; background-image: url(../images/nav-down-2.png); position: absolute; bottom: -19px; width: 100%; left: 0; right: 0; height: 19px; z-index: 10; background-repeat: no-repeat; background-position: center;}

.main-nav ul li a {color: #fff; font-size: 16px; line-height: 19px; text-transform: capitalize;}
.main-nav ul li .sub-menu li a::after {display: none;}
//.main-nav ul li:nth-child(2) .sub-menu li a:hover {color: #ca89b4;}
//.main-nav ul li:nth-child(3) .sub-menu li a:hover {color: #ca89b4;}
//.main-nav ul li:last-child .sub-menu li a:hover {color: #a4cec3;}




.main-nav .search-container {float: right;}
.main-nav .search-container a {height: 30px; width:30px; padding: 9px; background-position: center; background-image: url(../images/mag.png); background-repeat: no-repeat; display: block; background-size: 27px;}
#bar-1 {
	background: #47a088;
	height: 7px;
	border-bottom: solid 2px #894070;
}

#bar-2 {
	background: #b75696;
	height: 6px;
}

#bar-3 {
	background: #792850;
	height: 9px;
}

.home-button,
div.menu-button,
div.search-button {
	width: 195px;
	position: absolute;
	text-align: center;
	font: 20px;
}

.home-button {
	display: block;
	background: url('../images/top-bar-button-home.png') center bottom no-repeat;
	cursor: pointer;
	color: #fff;
	height: 56px;
	top: 0;
	right: 400px;
	line-height: 48px;
	z-index: 200;
}

.home-button:hover {
	color: #fff;
}

div.menu-button {
	background: url('../images/top-bar-button-menu.png') center bottom no-repeat;
	cursor: pointer;
	color: #fff;
	height: 62px;
	top: 0;
	right: 200px;
	line-height: 60px;
	z-index: 200;
}

div.search-button {
	background: url('../images/top-bar-button-search.png') center bottom no-repeat;
	cursor: pointer;
	position: absolute;
	color: #fff;
	height: 66px;
	right: 0;
	top: 5px;
	line-height: 68px;
	z-index: 200;
}

header {
	height: 172px;

	@media (max-width: 1000px){
		height: 195px;
	}

	@media (max-width: 580px){
		height: 142px;
	}

	@media (max-width: 578px){
		height: 162px;
	}

	@media (max-width: 350px){
		height: 182px;
	}
}

header img.logo {
	position: absolute;
	top: 21px;
	left: 0;
	z-index: 1000;
	width: 217px;
	height: auto;
}

header h2 {
	padding-top: 44px;
	text-align: right;
	line-height: 28px;
}

header h2 span { font-size: 30px; }

header{

	.header-top{
		background-color: $purple-dark;
		padding: 10px 0px;

//		@media (max-width: 1000px){
//			display: none;
//		}

		.container{
			display: flex;
			justify-content: space-between;
		}

		form{
			margin-bottom: 0;

			@media (max-width: 1000px){
				display: none;
			}

			input[type="text"]{
				height: 31px;
				border-radius: 11px;
				width: auto;
				min-width: 167px;
				padding: 0 39px 0 13px;
				background-image: url(../images/search.svg);
				background-repeat: no-repeat;
				background-position: top 50% right 8px;
				margin: 0;
			}
		}

		.number-con{

			@media (max-width: 580px){
				display: block;
				width: 100%;
			}

			.number{
				@include font-size(20);

				@media (max-width: 1000px){
					padding-right: 55px;
					padding-left: 55px;
				}

				@media (max-width: 945px){
					@include font-size(14);
				}

				@media (max-width: 700px){
					padding-right: 43px;
					padding-left: 43px;
				}

				@media (max-width: 580px){
					text-align: center;
				}

				span,
				a{
					color: white;
					font-weight: 700;

					&:hover,
					&:focus{
						color: $green;
					}
				}

				a{
					display: inline-block;
					word-break: normal;
					white-space: nowrap;
				}
			}
		}
	}
}

.you-may-also{
	text-align: center;
	padding-top: 44px;
	padding-bottom: 42px;

	+ .main.front-page{
		margin-bottom: -6px;
	}

	h2{
		margin:0 auto;
		text-transform: uppercase;
		@include font-size(25);
		@include line-height(25,30);
	}
}

.mobile-menu{

	@media (min-width: 1000px + 1){
		display: none!important;
	}
}

#menu-item-4560{

	ul{
		min-width: 186px;
	}

//	ul li a{
//		padding-left: 44px;
//	}
}


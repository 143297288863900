//---------------------------------------------------------//

@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
//@import 'general-tags-and-classes';
@import 'grid';/* adapted boostap 4 grid*/
@import 'main-menu';
@import 'page';
@import 'footer';
//@import 'general-overights'; /* stuff like float-left, clear-right, align-center */
//@import 'buttons';
//@import 'forms';
//@import 'slick';
//@import 'slick-theme';

//
//!!SOCIAL MENU START
//
$social-size:		24;
$social-addition:	0;

$facebook:		#3b5999;
$twitter:		#55acee;
$linkedin:		#007bb5;
$pinterest:		#cb2027;
$rss:			#ff6600;
$slideshare:	#f7941e;
$google-plus:	#dd4b39;
$instagram:		#8a3ab9;
$reddit:		#ff4500;
$youtube:		#bb0000;
$mail:			#767676;
$behance:		#131418;

//.social-menu{
//	margin-bottom: 0;
//	display: flex;
//	align-items: center;
//	justify-content: center;
//
//	li{
//		list-style: none;
//		margin: 0 6px 0px;
//		@include font-size($social-size);
//		vertical-align: middle;
//
//		@media (max-width: $b2){
//		@include inline;
//		vertical-align: middle;
//		}
//
//		&:first-of-type{
//			margin-left: 0px;
//		}
//
//		&:last-of-type{
//			margin-right: 0px;
//		}
//
//		&.text{
//		  @include font-size($social-size);
//		}
//
//		&.icon.icon a{
//			@include font-size($social-size);
//			line-height: $social-size + $social-addition + px;
//			height:  $social-size + $social-addition + px;
//			min-width: $social-size + $social-addition + px;
//			display: block;
//			text-align: center;
//			color: $blue;
//			text-decoration: none;
//			background-color: transparent;
//			background-color: transparent;
//			background-image: none;
//			padding: 0;
//			font-weight: 100;
//
//			i{
//				@include font-size($social-size);
//				line-height: $social-size + $social-addition + px;
//				min-width: $social-size + $social-addition + px;
//				display: block;
//			}
//		}
//
//		&.facebook.icon a{
//
//			&:hover,
//			&:focus{
//				color: $facebook!important;
//				color: $orange!important;
//			}
//		}
//
//
//		&.behance.icon a{
//
//			&:hover,
//			&:focus{
//				color: $behance!important;
//				color: $orange!important;
//			}
//		}
//
//		&.twitter.icon a{
//
//			&:hover,
//			&:focus{
//				color: $twitter!important;
//				color: $orange!important;
//			}
//		}
//
//		&.linkedin.icon a{
//
//			&:hover,
//			&:focus{
//				color: $linkedin!important;
//				color: $orange!important;
//			}
//		}
//
//		&.pinterest.icon a{
//
//			&:hover,
//			&:focus{
//				color: $pinterest!important;
//			}
//		}
//
//		&.rss.icon a{
//
//			&:hover,
//			&:focus{
//				color: $rss!important;
//				color: $orange!important;
//			}
//		}
//
//		&.slideshare.icon a{
//
//			&:hover,
//			&:focus{
//				color: $slideshare!important;
//				color: $orange!important;
//			}
//		}
//
//		&.google-plus.icon a{
//
//			&:hover,
//			&:focus{
//				color: $google-plus!important;
//				color: $orange!important;
//			}
//		}
//
//		&.instagram.icon a{
//
//			&:hover,
//			&:focus{
//				color: $instagram!important;
//				color: $orange!important;
//			}
//		}
//
//		&.reddit.icon a{
//
//			&:hover,
//			&:focus{
//				color: $reddit!important;
//				color: $orange!important;
//			}
//		}
//
//		&.youtube.icon a{
//
//			&:hover,
//			&:focus{
//				color: $youtube!important;
//				color: $orange!important;
//			}
//		}
//
//		&.mail.icon a{
//
//			&:hover,
//			&:focus{
//				color: $mail!important;
//				color: $orange!important;
//			}
//		}
//	}
//}

//
//!!SOCIAL MENU END
//

//
//!!PAGE SPECIFIC
//

html{
	font-size:62.5%;
	font-weight: 500;
}

::-webkit-input-placeholder {
	color: #000;
}

:-moz-placeholder { /* Firefox 18- */
	color: #000;
}

::-moz-placeholder {  /* Firefox 19+ */
	color: #000;
}

:-ms-input-placeholder {
	color: #000;
}

.waypoint{
	transition: .75s;
	opacity: 0;
	visibility: hidden;
	transform: translateY(20px);
	will-change: transform;

	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}

/* Global Classes
================================================== */
.container {
	//width: 960px;
	width: 1140px;
	margin: 0 auto;
	position: relative;
	overflow: visible;
	max-width: 98%;
}

.left { float: left; }
.right { float: right; }

/* Site-specific Styles
================================================== */



/* Social Icons */
ul.social-icons {
	background: #fff;
	margin: 0;
	padding: 14px 16px;
	padding: 6px 5px 0;
	list-style-type: none;
	position: fixed;
	top: 174px;
	left: 0;
	background-color: #939598;
	z-index: 1;

	@media (max-width: 1238px){

	}
}

ul.social-icons li {
	width: 35px;
	height: 36px;
	margin: 4px 0;
	color: #3f3b3b;
	font-size: 12px;
	text-align: center;
	color: white;

	&.text{
		@include font-size(7);
		text-transform: uppercase;
		height: auto;
		width: auto;
		line-height: 1em;
		margin: 4px 0 6px;
	}

	&.icon{
		height: auto;
		width: auto;
		@include font-size(22);

		a{
			color: white;

			&:hover,
			&:focus{
				color: $purple;
			}
		}
	}
}

ul.social-icons li.text { height: auto; }

.media-index {margin-bottom: 20px !important;}
.media-index h2 {color: #fff; font-size: 16px; line-height: 30px; display: inline;}
.media-index a {color: #b65695; font-size: 16px; line-height: 30px; display: inline; text-decoration: underline;}
.media-index a:hover {text-decoration: none;}
.media-index span {float: right; font-size: 16px; line-height: 22px; color: #fff; margin-right: 20px;}
@media only screen and (max-width: 1000px) {
	.media-index span {margin: 0;}
}
article.media-single {padding: 0 !important;}
.sidebar-inner {padding: 25px 0 25px 25px; background-color: #fff; margin: 0 0 12px; overflow: hidden;}
.widget_collapsarch {padding: 25px 0 25px 25px; background-color: #fff; margin: 30px 0 12px; overflow: hidden;}
.widget_collapsarch h3 {font-size: 20px; line-height: 24px; color: #4b9e87; border-bottom: dotted 2px #4b9e87; padding: 0 0 15px;}
.widget_collapsarch ul {margin: 0;}
.widget_collapsarch ul li {}
.widget_collapsarch ul li a {font-size: 16px;
	line-height: 19px;
	color: #000;}
.widget_collapsarch ul li > div ul li {padding: 0 0 0 35px;}
.widget_collapsarch ul li > div ul li a {color: #666666;}
.widget_collapsarch ul li span.expand a {background-image: url(../images/arrow-right.png); background-repeat: no-repeat; background-position: 0 6px; padding: 0 0 0 20px;}
.widget_collapsarch ul li span.collapse a {background-image: url(../images/archive-down.png); background-repeat: no-repeat; background-position: 0 8px; padding: 0 0 0 20px;}


.sidebar-inner h3 {font-size: 20px; line-height: 24px; color: #4b9e87; border-bottom: dotted 2px #4b9e87; padding: 0 0 15px;}
.sidebar-inner h4 {font-size: 20px; line-height: 24px; color: #b45894; border-bottom: dotted 2px #b45894; padding: 0 0 15px;}
.sym {display: none !important;}
.widget_accordion_archive ul {margin-bottom: 45px !important; }
.widget_accordion_archive ul li.archive-accordion-year {background-image: url(../images/arrow-right.png); background-repeat: no-repeat; background-position: 0 6px; padding: 0 0 0 20px;}
.widget_accordion_archive ul li.archive-accordion-year.active {background-image: url(../images/archive-down.png); background-repeat: no-repeat; background-position: 0 8px; padding: 0 0 0 20px;}
.widget_accordion_archive ul li a {font-size: 16px; line-height: 19px; color: #000;}
.widget_accordion_archive ul li ul li {padding: 0 0 0 35px;}
.widget_accordion_archive ul li ul li a {color: #666666;}
.sidebar-inner ul { padding: 0; margin: 0; list-style-type: none; }
.sidebar-inner li.categories {list-style: none; font-size: 20px; line-height: 24px; color: #4b9e87;}
.sidebar-inner li.categories > ul {border-top: dotted 2px #4b9e87; margin-top: 10px; padding-top: 10px; margin-bottom: 30px;}
.sidebar-inner li.categories > ul li {margin: 0 0 10px;}
.sidebar-inner li.categories > ul li a {color: #000; font-size: 16px; line-height: 19px;}

.post-left {
	width: 28%;
	width: 19.2%;
	overflow: hidden;
}
.post-box {
	width: 72%;
	width: 79.7%;
	background: #282829;
	background: transparent;
	overflow: hidden;
	padding: 12px 0 0 0;
}

.post-box article:last-of-type .new-content::after {border-bottom: solid 4px #cbcbcb;

}

.post-box article {
	font-size: 16px !important;
}
.dots {position: relative; padding: 10px 0; margin: 0 0 20px;}
.dots::after {
background-image: url('../images/nav-column-title-background.png'); background-repeat: repeat-x;background-position: center; position: absolute; bottom: 0;
left: 32px; content: ""; display: block; height: 2px; width: 92%;
}
.dots h3.title {margin: 25px 0;}

.post-type-archive-media .dots::after {width: 95%;}
.post-type-archive-media .bread {margin: -15px 0 0 0;}
.post-type-archive-media .navigation {}

.bread { text-align: right; margin: -15px 28px 0 0; float: right;}

.breadcrumbs a {
	color: white;
	font-size: 13px;

	&:hover{
		color: #8f3d71;
	}
}
.breadcrumbs span {
	color: white;
	font-size: 13px;
}

.breadcrumbs a::after {
	content: "\2022";
	padding: 0 3px 0 3px;
	color: white!important;
}


.white-text {color: #fff; font-size: 16px; line-height: 19px;}
.green-text {color: #46a28a; font-size: 20px; line-height: 24px;}
.new-content {padding: 0 32px 40px 0; position: relative;}
.new-content::after {position: absolute; content: ""; bottom: 0; left: 0; right: 5%; border-bottom: dotted 4px #989898;}
.new-content h2 {color: #b45894; font-size: 25px; line-height: 30px; margin: 0;}
.new-content h5 {font-size: 20px; line-height: 24px; color: #fff !important; text-transform: uppercase; margin: 0 0 10px;}
.categories {}
.sidebar-inner .categories ul li {display: list-item;}
.categories ul.post-categories {margin: 0 0 20px; padding: 0; list-style-type: none;}
.categories ul.post-categories li {display: inline-block;}
.categories ul.post-categories li:first-child::before {display: none;}
.categories ul.post-categories li::before {content: "\2022"; padding: 0 3px 0 0;}
.categories ul.post-categories li a {font-size: 18px; line-height: 22px; color: #4b9e87;}


.sharings span {font-size: 18px; line-height: 22px; color: #fff;}
.sharings ul {margin: 10px 0; padding: 0; list-style-type: none;}
.sharings ul li {display: inline-block; vertical-align: middle;}
.sharings ul li a {display: block; height: 45px; width: 46px; background-image: url(../images/social.png); background-repeat: no-repeat;}
.sharings ul li.facebook a {background-position: 0;}
.sharings ul li.twitter a {background-position: -50px;}
.sharings ul li.pluses a {background-position: center;}
.sharings ul li.mails a {background-position: -197px;}
.sharings ul li.linkedin a {background-position: -149px;}

.new-content p {color: #fff; font-size: 16px; line-height: 23px; text-align: left !important;}
.new-content a.btn {color: #fff; font-size: 18px; line-height: 21px; background-color: #b45894; padding: 11px; display: block; width: 100px; text-align: center;}
.new-content a.btn:hover {color: #b45894; background-color: #fff;}

.navigation { padding: 0 0 20px 32px;}
ol.wp-paginate li a {border:none !important; background-color: transparent !important; font-size: 18px; line-height: 22px;}
ol.wp-paginate li a {color: #fff !important;}
.wp-paginate .current, .wp-paginate a:hover, .wp-paginate a:active {
	color: #b45894 !important; border:none !important; background-color: transparent !important; font-size: 18px; line-height: 22px;
}
.blog .trade-customers {display: none;}

.single-media .cat-list {display: none;}

.single-recipe p > strong {color: #46a28a;}
.recipe-image {margin: 10px 0; width: 100%; height: 350px; background-size: cover; background-position: center; background-repeat: no-repeat; height: auto; padding-top: 38.5%;
}
.single-product .post-box, .post-type-archive-recipe .post-box,
.post-type-archive-media .post-box {
	//padding: 12px 32px 50px 0;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
}
 .single-recipe .post-box {padding: 12px 32px 50px;box-sizing: border-box;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;}

.single-media .post-box {padding: 12px 32px 50px; box-sizing: border-box;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;}
.post-type-archive-recipe .post-box .left {  width: 22%; }
.post-type-archive-recipe .post-box .right {width: 78%; box-sizing: border-box;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box; padding-right: 10px;}
.post-type-archive-recipe .post-box .right h3 {margin: 0 0 10px !important; color: #b65695;}
.post-type-archive-recipe .post-box .right p {margin: 0 0 10px;}
.post-type-archive-recipe .post-box .right .perm {color: #fff; text-decoration: underline;}
.post-type-archive-recipe .post-box .right .perm:hover {text-decoration: none;}
.post-type-archive-recipe .sidebar-inner ul li a, .single-recipe .sidebar-inner ul li a {color: #000;}
.post-type-archive-recipe .sidebar-inner ul li a:hover {color: #b65695;}
.post-type-archive-recipe .sidebar-inner {padding-bottom: 75px;}
.post-type-archive-recipe .post-box article:last-of-type::after, .post-type-archive-media .post-box article:last-of-type::after {
	border-bottom: solid 4px #cbcbcb;
	height: 4px;
	width: 97%;
	padding-top: 20px;
}

.post-type-archive-product .sidebar-inner.hide {display: none !important;}
.post-type-archive-product section.main.generic aside div.link.find-recipes {margin-top: 7.5px;}
.post-type-archive-product .sidebar-inner.show-sidebar {display: block !important;}
.post-type-archive-product .trade-customers {display: none !important;}
.post-type-archive-product div.siblings {display: none;}


.post-type-archive-media .sidebar-inner.hide {display: none !important;}
.post-type-archive-media section.main.generic aside div.link.find-recipes {margin-top: 7.5px;}
.post-type-archive-media .sidebar-inner.show-sidebar {display: block !important;}
.post-type-archive-media .trade-customers {display: none !important;}

.single-media div.trade-customers {display: none;}

.post-type-archive-recipe.archive .sidebar-inner.hide {display: none !important;}
.post-type-archive-recipe.archive .sidebar-inner {display: none !important;}
.post-type-archive-recipe.archive .sidebar-inner.show-sidebar {display: block !important;}
.post-type-archive-recipe.archive .trade-customers {display: none !important;}


.recipe-template-default.single.single-recipe .sidebar-inner.hide {display: none !important;}
.recipe-template-default.single.single-recipe .sidebar-inner {display: none !important;}
.recipe-template-default.single.single-recipe .sidebar-inner.show-sidebar {display: block !important;}
.recipe-template-default.single.single-recipe .trade-customers {display: none !important;}

.product-template-default.single.single-product .bread {margin: -15px 0 0 0;}

.single-recipe .sibebar-inner.hide {display: none !important;}
.single-recipe .dots::after {left: 0; width: 100%;}
.single-recipe .bread {margin: -15px 0 0 0;}
.single-recipe div.siblings, .single-recipe .trade-customers {display: none;}
.post-type-archive-media .post-box article:last-of-type::after {width: 100%;}
.post-type-archive-media section.main.generic article, .post-type-archive-recipe section.main.generic article, .single-recipe section.main.generic article, .single-media section.main.generic article { width: 100%;}
/*.post-type-archive-recipe .dots::after, .post-type-archive-media .dots::after, .single-recipe .dots::after, .single-media .dots::after {width: 100%; left: 0;}*/
/*.post-type-archive-recipe h3.title, .post-type-archive-media h3.title, .single-recipe h3.title, .single-media h3.title {padding: 0 !important;}*/
.sidebar-inner {margin: 30px 0 9px 0;}
.single-media h3.title {padding: 0 !important;}
.single-media .dots::after {left: 0; width: 100%;}
.single-media .bread {margin: -15px 0 0 0;}
.single-media .sidebar-inner.hide {display: none !important;}
.single-media section.main.generic aside div.link.find-recipes {margin-top: 7.5px;}
.post-type-archive-recipe div.siblings {display: none;}
.sidebar-inner a {font-size: 16px;}
.single-recipe .find-recipes {display: none;}

.single .new-content::after {border: none !important;}

.media-single .btn {color: #fff; font-size: 18px; line-height: 21px; background-color: #b45894; padding: 11px; display: block; width: 155px; text-align: center;}
.media-single .btn:hover {color: #b45894; background-color: #fff;}

.single-product .sidebar-inner, .single-product div.siblings {display: none;}
.single-product .trade-customers {padding-top: 50px; background-image: url(../images/baskets.jpg); background-size: 40px; background-position: 50% 15%; background-repeat: no-repeat;}
.single-product .trade-customers a {font-size: 20px; line-height: 24px; color: #000;}
.single-product .trade-customers a span {font-size: 20px; line-height: 24px; color: #8f3d71;}
.single-post .trade-customers {display: none;}

.post-template-default.single.single-post .title-holder {padding-top: 20px;}

.post-template-default.single.single-post .blog-link {display: inline-block; vertical-align: middle; font-size: 21px; line-height: 25px; text-decoration: underline;
}
.post-template-default.single.single-post h3.title::before {content: "\2022"; padding: 0 3px 0 3px;}

.post-template-default.single.single-post .blog-link:hover {text-decoration: none;}
.post-template-default.single.single-post h3.title {display: inline-block; vertical-align: middle; margin: 0;}

.left-columns, .right-columns {width: 49%;}
.left-columns p > a > img {max-width: 95%; height: auto;}

.right-columns p > a > img {max-width: 95%; height: auto;}

.left-columns p > img {max-width: 95%; height: auto;}

.right-columns p > img {max-width: 95%; height: auto;}

@media only screen and (max-width: 479px) {
	.left-columns, .right-columns {
		width: 100%;
		float: none;
	}
	.mobile-cols {
	margin-bottom: 50px !important;
	}
	.col-3 #menu-about, .col-3 .menu-item-4680  {
	margin-bottom: 50px !important;
	}

	.col-4 ul li {
	margin-bottom: 0 !important;
	}
}

/* Front-page/Products */
section.main.products p.no-products {
	margin: 30px 0 20px;
	font-size: 18px;
}

section.main.front-page ul.shelves,
section.main.products ul.shelves {
	background: url('../images/shelf-large.png') top center;
	width: 940px;
	margin: 0 0 0 -30px;
	padding: 0 40px;
	list-style-type: none;
	background-image: url(../images/shelf-update.png);
	background-repeat: repeat-y;
	background-size: 91% 348px;


	width: 100%;
	margin: 0 auto;
	background-position: 50% 0%;
	padding: 0 37px;
	box-sizing: border-box;

}

section.main.front-page ul.shelves,
section.main.products ul.shelves{

	@media (max-width: 1035px){
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
}

section.main.front-page ul.shelves li,
section.main.products ul.shelves li {
	float: left;
	width: 130px;
	height: 315px;
	margin: 0 0 0 4px;

	width: 152px;
	height: 348px;
	padding: 0 4px;
	margin: 0;
	box-sizing: border-box;
	width: 14.2%;

	@media (max-width: 1035px){
		width: 131px!important;
		margin-left: 2px!important;
		margin-right: 2px!important;
	}

//	@media (max-width: 930px){
//		width: 16%;
//		margin-left: 3%!important;
//	}
//
//	@media (max-width: 660px){
//		width: 28%;
//		margin-left: 3%!important;
//	}
//
//	@media (max-width: 450px){
//		width: 50%;
//		margin-left: auto!important;
//	}


	figure{

		&:hover{


			+ h5 a{
				text-decoration: underline;
			}
		}
	}



	h5{
		margin-top: 36px!important;
		@include font-size(18);
		@include line-height(18,21.6);

		a{

			&:hover,
			&:focus{
				text-decoration: underline;
			}
		}
	}
}

section.main.front-page ul.shelves li figure,
section.main.products ul.shelves li figure {
	display: block;
	height: 237px;
	margin: 0 auto;
	overflow: hidden;

	img{
		margin: 0 auto;
		display: block;
	}

	a{
		margin: 0 auto;
		display: block;

		height: 238px;
		display: flex;
		justify-content: center;
		align-items: flex-end;

		img{
			max-width: 100%;
		}
	}
}

section.main.front-page ul.shelves li h5,
section.main.products ul.shelves li h5 {
	margin-top: 30px;
	text-align: center;
}

section.main.front-page ul.shelves li h5 a,
section.main.products ul.shelves li h5 a { color: #fff; }
section.main.front-page div.secondary,
section.main.products div.secondary {
	height: 344px;
	position: relative;
}
.left-products {width: 44%; float: left;}

section.main.front-page div.secondary div.chalkboard,
section.main.products div.secondary div.chalkboard {
	background: url('../images/chalkboard.png');
	width: 574px;
	height: 344px;
	position: absolute;
	top: 0;
	left: 92px;
	z-index: 1;
}

section.main.front-page div.secondary div.chalkboard h2,
section.main.products div.secondary div.chalkboard h2 {
	width: 470px;
	margin-top: 72px;
	margin-left: 54px;
	line-height: 32px;
}

section.main.front-page div.secondary img.sale,
section.main.products div.secondary img.sale {
	position: absolute;
	left: 0;
	bottom: 0;
	z-index: 2;
}

section.main.front-page div.secondary img.new-products,
section.main.products div.secondary img.new-products {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 2;
}

.amazon-box {margin: 20px 0 0; padding: 15px 25px; width: 100%; background-color: #fff; text-align: center; box-sizing: border-box;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;}
.amazon-box p {margin: 0 0 15px; color: #000; font-size: 20px; line-height: 24px;}
.amazon-box .inners {border-bottom: dotted 5px #000;}
.amazon-box img {margin-bottom: 10px;}
.post-left aside {width: 100% !important;}
.post-left .find-recipes {background-position: 200px 16px !important;}
.post-left .get-in-touch {background-position: 200px 16px !important;}
.single .product .dots::after {width: 97%;}

.footer-nav {background-color: #782a50; text-align: center; padding: 15px 0;}
.foot-nav ul {margin: 0; padding: 0; list-style-type: none;}
.foot-nav li {display: inline-block; margin: 0 30px 0 0;}
.foot-nav li:last-child {margin: 0;}
.foot-nav a {display: block; font-size: 14px; line-height: 18px; text-transform: capitalize; color: #fff;}
.foot-nav a:hover {text-decoration: underline;}

.post-type-archive-recipe .bread {margin: -15px 0 0 0 !important;}
.post-type-archive-recipe .dots::after {width: 95% !important;}

.single-recipe section.main.generic article {padding-left: 0;}
.single-recipe section.main.generic article ul {margin: 0;}
/* Generic Template */
section.main.generic aside {
	width: 236px;
	margin-top: 7.5px;

	@media (min-width: 1000px + 1){
		width: 236px;
		float: left;
	}

	@media (max-width: 1000px){
		width: 100%;
		float: none;
	}
}

section.main.generic aside div.siblings {
	background: #fff;
	margin-bottom: 14px;
	padding-left: 22px;
	overflow: hidden;
	position: relative;
}

section.main.generic aside div.siblings h3 {
	background: url('../images/dotted-border-purple.png') bottom left repeat-x;
	margin-bottom: 10px;
	padding: 28px 0 20px;
}

section.main.generic aside div.siblings ul {
	margin: 0;
	padding-bottom: 24px;
	list-style-type: none;
}

section.main.generic aside div.siblings ul li {
	font-size: 15px;
	line-height: 28px;
}

section.main.generic aside div.siblings ul div.hidden { display: none; }
section.main.generic aside div.siblings ul span.show-hidden-items {
	display: block;
	background: url('../images/icons/arrow.png') no-repeat;
	width: 14px;
	height: 8px;
	position: absolute;
	bottom: 10px;
	right: 10px;
}

section.main.generic aside div.siblings ul span.show-hidden-items:hover { cursor: pointer; }
section.main.generic aside div.siblings ul span.show-hidden-items.open { background: url('../images/icons/arrow-rotated.png'); }
section.main.generic aside div.siblings ul li a { color: #000; }
section.main.generic aside div.link {
	float: right;
	height: 72px;
}

section.main.generic aside div.link a {
	display: block;
	padding-left: 18px;
	color: #fff;
	font-size: 20px;
	line-height: 72px;
}

section.main.generic aside div.link.find-recipes {
	background: #47a088 url('../images/icons/find-recipes.png') 164px 16px no-repeat;
	width: 100%;
	margin-bottom: 9px;
}

section.main.generic aside div.link.get-in-touch {
	background: #b75696 url('../images/icons/get-in-touch.png') 148px 16px no-repeat;
	width: 100%;
	margin-bottom: 7px;
}

section.main.generic aside div.trade-customers {
	float: right;
	background: #993366 url('../images/icons/trade-customers.png') 168px 24px no-repeat;
	width: 100%;
	height: 87px;
}

section.main.generic aside div.trade-customers a {
	display: block;
	padding-top: 20px;
	padding-left: 16px;
	color: #fff;
	font-size: 18px;
}

section.main.generic aside div.trade-customers a span {
	display: block;
	font-size: 15px;
}

section.main.generic article {
	background-color: #282829;
	width: 663px;
	margin-bottom: 10px;
	padding-left: 32px;
	padding-top: 10px;

	@media (min-width: 1000px + 1){
		width: calc(100% - 268px);
	}

	@media (max-width: 1000px){
		width: 100%!important;
		box-sizing: border-box;
		padding-left: 15px;
		padding-right: 15px;

		*{
			box-sizing: border-box;
		}
	}

	@media (max-width: $b3){
	}


}

.page-template-content-contact .sidebar-inner {display: none;}

section.main.generic article div.video {
	background: #fff;
	width: 582px;
	margin: 20px 0 30px 22px;
	padding: 4px;
}

.tax-recipe_category .siblings, .tax-recipe_category .sidebar-inner {display: none;}

section.main.generic article div.video iframe { float: left; }
section.main.generic article div.text { margin-bottom: 20px; }
section.main.generic article ul.three-columns {
	margin: 0;
	margin-bottom: 40px;
	padding-left: 20px;
	overflow: hidden;
}

section.main.generic article ul.three-columns li {
	float: left;
	width: 33%;
}

/* Product */
section.main.product article figure {
	width: 298px;
	/*height: 298px;*/
	position: relative;
	margin-bottom: 30px;
}

.single-product hr {border: none; border-bottom: dotted 2px #666666;}
.single-product p > strong {color: #4b9e87;}
section.main.product article figure .back-link {
	display: block;
	background-color: #47a088;
	width: 60px;
	height: 28px;
	position: absolute;
	top: 14px;
	left: -3px;
	color: #fff;
	font-size: 14px;
	line-height: 28px;
	text-align: center;;
}

section.main.product article figure .back-link:before {
	content: " ";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 14px 8px 14px 0;
	border-color: transparent #47a088 transparent transparent;
	position: absolute;
	top: 0;
	left: -8px;
}

section.main.product article figure img {
	display: block;
	max-width: 95%;
	height: auto;
}

section.main.product article figure figcaption {
	background: #b75696;
	width: 224px;
	padding: 24px;
	position: absolute;
	left: 12px;
	bottom: -72px;
	color: #fff;
	font-size: 18px;
	text-align: center;
}

section.main.product article { position: relative; }
section.main.product article div.text {
	width: 320px;
	margin-right: 24px;
}

section.main.product article a.buy-now {
	display: block;
	background: url('../images/amazon-buy-now.png');
	width: 195px;
	height: 113px;
	position: absolute;
	top: -3px;
	right: 26px;
	color: #fff;
	font-size: 16px;
	line-height: 36px;
	text-align: center;
}

section.main.product article a.buy-now span {
	display: block;
	margin-top: 14px;
}

section.main.product article a.download-link {
	display: block;
	background-image: url('../images/icons/technical-specification.png');
	background-repeat: no-repeat;
	background-position: 90% 50%;
	height: 40px;
	margin-top: 10px;
	padding: 10px 46px 20px 10px;
	color: #fff;
	line-height: 40px;
	background-color: #4b9e87;
	font-size: 20px;
	line-height: 24px;
}

section.main.product article ul.logos {
	margin: 30px 0 0;
	list-style-type: none;
}

section.main.product article ul.logos li {
	float: left;
	margin-right: 20px;
}

section.main.product article h2.byp { margin-top: 30px; color: #b15d9d; }
section.main.product article figure.byp {
	background: none;
	width: 115px;
	height: auto;
	margin-top: 4px;
	margin-right: 10px;
}

section.main.product article figure.byp img { margin: 0; }
section.main.product article div.byp-text { width: 170px; }
section.main.product article div.byp-text.full-width { width: 295px; }

/* Page */
section.main.page article h3 { margin-bottom: 30px !important; }
section.main.page article div.content { padding: 0 60px 40px 0; }
section.main.page article div.content.remove-left-padding { padding: 0 60px 40px 0; }
section.main.page article h4,
section.main.page article h5,
section.main.page article h6 { color: #b75696; }

section.main.page article .content .column {
	width: 33.3%;
}

section.main.page article .content .column ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

/* Awards */
section.main.awards ul.awards {
	width: 90%;
	margin: 0 auto 40px;
	list-style-type: none;
}

section.main.awards ul.awards li {
	float: left;
	margin-right: 30px;
}

/* Listings */
section.main.listings{
	padding-top: 40px;

	p{
		font-size: 18px;
		line-height: 1.2;
	}
}


section.main.listings ul.listings {
	margin: 40px 0 80px 42px;
	list-style-type: none;
}

section.main.listings ul.listings li {
	width: 528px;
	margin-bottom: 32px;
	width: 100%!important;

	.full-width{
		width: 100%!important;
	}
}

section.main.listings ul.listings li figure {
	float: left;
	width: 115px;
	margin-right: 32px;
}

section.main.listings ul.listings div.text { width: 380px; }
section.main.listings ul.listings div.text.full-width { width: 560px; }
section.main.listings ul.listings li h4 { color: #b75696; }
section.main.listings ul.listings li p { margin-bottom: 8px; }
section.main.listings ul.listings li p a {
	color: #fff;
	text-decoration: underline;
}

section.main.listings ul.listings li p a:hover { text-decoration: none; }

/* Contact */
section.main.contact article div.column-one,
section.main.contact article div.column-two { margin-bottom: 60px; }
section.main.contact article div.column-one {
	width: 240px;
	margin-right: 50px;
}

section.main.contact article div.column-one h2.telephone {
	background: url('../images/icons/telephone-black.jpg') left center no-repeat;
	height: 36px;
	margin-bottom: 32px;
	padding-left: 54px;
	font-size: 28px;
	line-height: 36px;
}

section.main.contact article div.column-one ul.non-float-social-icons {
	margin: 0;
	list-style-type: none;
}

section.main.contact article div.column-one ul.non-float-social-icons li {
	clear: both;
	margin-bottom: 6px;
	line-height: 36px;
}

section.main.contact article div.column-one ul.non-float-social-icons li img {
	float: left;
	margin-right: 10px;
}

section.main.contact article div.column-one ul.non-float-social-icons li a { color: #fff; }
section.main.contact article div.column-two { width: 310px; }
section.main.contact article div.column-two form textarea {
	height: 200px;
	padding-top: 12px;
}


.page-template-default .sidebar-inner, .page-template-default div.siblings {display: none;}
.main.contact article .column-two form input[type="text"],
.main.contact article .column-two form input[type="email"],
.main.contact article .column-two form textarea {
	width: 100%;
}

.main.contact article .column-two form input[type="text"],
.main.contact article .column-two form input[type="email"] {
	height: 54px;
	line-height: 54px;
}

.main.contact article .column-two form input[type="submit"] {
	height: 54px;
	line-height: 54px;
}

/* How to find us */
section.main.how-to-find-us article ul.locations {
	margin: 0;
	list-style-type: none;
}
.back-list {
	padding-top: 239px;
	text-align: center;
	height: auto !important;
	background-image: url(../images/back.png);
	background-repeat: no-repeat;
	background-position: 100% 100%;
	position: relative;
	display: none;

}
.back-list input[type="button"] { text-transform: uppercase; color: #fff; font-size: 20px; line-height: 24px; border: none; box-shadow: none; padding: 0; background-color: transparent; cursor: pointer; position: absolute; bottom: 23px; left: 10px; right: 0; width: 100%; text-align: center; font-weight: bold;}
section.main.how-to-find-us article ul.locations li { margin-bottom: 30px; }
section.main.how-to-find-us article ul.locations li div.map {
	float: left;
	width: 298px;
	height: 298px;
	margin-right: 40px;
}

section.main.how-to-find-us article ul.locations li div.text {
	float: left;
	width: 240px;
}

/* Footer */
footer {padding: 44px 0 0 0;}
footer div.tablet-mobile-friendly { display: none; }
footer div.kitchen-utilities {


}

footer div.left-image {background-image: url(../images/new-frames.png); background-repeat: no-repeat; height: 600px; background-size: contain; background-position: 50% 0;}
footer div.left-inner {margin: 0 auto;}
footer div.left .slider {position: absolute;top: 25px; right: 38px; left: 42px;}
footer div.left {width: 40%; position: relative;}
footer div.right {width: 56%; padding-bottom: 50px;}
footer div.tablet-mobile-friendly div.drawers { position: relative; }
footer div.tablet-mobile-friendly div.drawers div.text,
footer div.kitchen-utilities div.text {
width: 100%;
padding: 20px 0 0 20px;
clear: both;
}
.blog .new-content img {max-width: 95%; height: auto;}
footer div.tablet-mobile-friendly div.drawers ul.purple-social-icons,
footer div.kitchen-utilities ul.purple-social-icons {
	margin: 0;
	list-style-type: none;

}

footer div.kitchen-utilities ul.purple-social-icons li a {display: block;}
footer div.kitchen-utilities ul.purple-social-icons::after {
	background-image: url(../images/bg-shelf.png);
	background-position: bottom;
	background-repeat: no-repeat;

	content: "";
	left: 0;
	right: 0;
	bottom: -171px;
	height: 94px;
	display: block;
}
.extra-link {color: #000;}
.extra-link:hover {color: #b45894;}
footer div.kitchen-utilities ul.purple-social-icons li {width: 20%;}
footer div.kitchen-utilities ul.purple-social-icons li.dl-file {width: 33%; }
footer div.kitchen-utilities ul.purple-social-icons li a img {margin-bottom: -7px;}
.dl-file {position: relative; margin-right: 15px;}
.dl-file::after {background-image: url(../images/bg-brochure.png); background-position: bottom; background-repeat: no-repeat; height: 10px; display: block; content: "";}
.dl-file a {font-size: 16px; line-height: 19px; color: #6ba08a; text-align: left; font-weight: bold; padding: 10px; background-color: #fff; width: 63%; margin: 0 auto;}
.dl-file span {color: #000;}
.newsletter {padding: 30px 70px; text-align: center; background-image: url(../images/new-frame.png); background-repeat: no-repeat; background-position: 36% 100%; background-size: 452px;}
.newsletter a {color: #fff; text-decoration: underline; font-size: 28px; line-height: 40px; letter-spacing: 2px;}
.newsletter a:hover {text-decoration: none;}
.newsletter span { color: #fff;font-size: 28px; line-height: 40px; letter-spacing: 2px;}
footer div.tablet-mobile-friendly div.drawers ul.purple-social-icons li,
footer div.kitchen-utilities ul.purple-social-icons li {
	display: inline-block;
	vertical-align: bottom;
}

footer div.tablet-mobile-friendly div.drawers div.text div.column,
footer div.tablet-mobile-friendly div.drawers div.text div.column h4,
footer div.kitchen-utilities div.text div.column h4 { color: #b75696; font-size: 28px; line-height: 34px; margin: 0; text-transform: uppercase;}
footer div.tablet-mobile-friendly div.drawers div.text div.column h5,
footer div.kitchen-utilities div.text div.column h5 { color: #fff; font-size: 25px; line-height: 30px; margin: 0 0 25px; text-transform: uppercase;}
footer div.tablet-mobile-friendly div.drawers div.text div.column p,
footer div.kitchen-utilities div.text div.column p { color: #000; }
footer div.tablet-mobile-friendly div.drawers div.text div.column ul.tweets,
footer div.kitchen-utilities div.text div.column ul.tweets {
	margin: 0;
	list-style-type: none;
}

footer div.tablet-mobile-friendly div.drawers div.column ul.tweets li,
footer div.kitchen-utilities div.text div.column ul.tweets li {
	margin-right: 24px;
	margin-bottom: 18px;
	color: #fff;
	line-height: 18px;
	font-size: 14px;
}

footer div.tablet-mobile-friendly div.fridge { position: relative; }
footer div.tablet-mobile-friendly div.fridge div.tablet-magnets,
footer div.kitchen-utilities div.magnets {
	width: 428px;
	height: 460px;
	position: absolute;
	top: 100px;
	right: 30px;
}

footer div.tablet-mobile-friendly div.fridge div.tablet-magnets div.tablet-magnet,
footer div.kitchen-utilities div.magnets div.magnet { position: absolute; }
footer div.tablet-mobile-friendly div.fridge div.tablet-magnets div.tablet-magnet:hover,
footer div.kitchen-utilities div.magnets div.magnet:hover { cursor: move; }
footer div.small-print {
	width: 940px;
	margin: 0 auto;
}

footer div.small-print p {
	margin-top: 16px;
	font-size: 12px;
	line-height: 10px;
}

footer div.small-print p a { color: #fff; }
footer div.small-print ul {
	margin: 16px 0 0;
	list-style-type: none;
}

footer div.small-print ul li {
	float: left;
	height: 10px;
	margin-right: 6px;
	padding-right: 6px;
	border-right: 1px solid #fff;
	font-size: 12px;
	line-height: 8px;
}

footer div.small-print ul li.last {
	margin: 0;
	padding: 0;
	border-right: none;
}

footer div.small-print ul li a { color: #fff; }

.page-template-content-find-us .sidebar-inner {display: none;}
.archive .new-content img {height: auto; max-width: 95%;}

/* Mobile Styles
================================================== */
@media screen and (max-width: 1100px) {
	ul.social-icons { display: none; }
}

@media only screen and (min-width: 1001px) {
	.main-nav {display: block;}
}

@media screen and (max-width: 1000px) {
	.mobile-menu h2.large {
	margin: 15px 0 0 325px;
	padding: 0;
	text-align: left;
	line-height: 36px;
	}
	.sub-menu {display: block !important;}
	.mobile-cols {float: left; width: 20%; max-width: 20%; margin: 0 5% 0 0; padding: 0;}


	.col-4 {margin: 0;}
	.col-4 ul li a {display: block;
	background: url(../images/nav-column-title-background.png) bottom left repeat-x;
	margin-bottom: 16px;
	padding-bottom: 20px;
	font-size: 22px;}
	.col-4 ul li {margin-bottom: 40px;}
	.fixed {position: fixed; top: 0; right: 0; bottom: 0; left: 0; }
	.mobile-menu {position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 999; background-color: #b75696; overflow-y: auto; -webkit-overflow-scrolling: touch;}
	.mobile-menu .table {display: table; width: 100%; text-align: center; height: 100%;}
	.mobile-menu .cell {display: table-cell; vertical-align: middle;}
	.main-nav {display: none; float: none; position: relative; z-index: 1001; width: 95%; background-color: #b75696;}
	.mobile-menu input[type="text"] {max-width: 100%; width: 100%; display: block; margin: 0 auto; box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;}
	.mobile-menu input[type="submit"] {max-width: 300px; width: 100%; display: block; margin: 0 auto;}
	.mobile-nav {padding: 50px 0 0;}
	.mobile-cols ul li.menu-item-has-children > a {display: block;
	background: url(../images/nav-column-title-background.png) bottom left repeat-x;
	margin-bottom: 16px;
	padding-bottom: 20px;
	font-size: 22px;}
	.mobile-cols ul li .sub-menu li a {background: none;
	margin: 0;
	padding: 0;
	font-size: 15px;
	line-height: 18px;}
	.mobile-nav ul {margin: 0; padding: 50px 0; list-style-type: none;}
	.mobile-nav ul.children, .mobile-nav ul.sub-menu {margin: 0; padding: 0; list-style-type: none;}
	.mobile-nav li {display: list-item; width: 100%; margin: 0 0 10px; text-align: left;}
	.mobile-nav a {display: block; font-size: 20px; line-height: 24px; color: #fff;}
	.main-nav li {width: 100%; display: list-item; margin: 0 0 10px; }
	.main-nav ul li a {display: block;}

	#toggle {
	margin: 7px 0 0 0;
	cursor: pointer;
	display: block !important;
	width: 45px;
	height: 45px;
	float: right;
	position: relative;
	z-index: 1001;
	margin-top: -49px;
	border-radius: 50%; }
	#toggle span, #toggle span:before, #toggle span:after {
	  cursor: pointer;
	  border-radius: 1px;
	  height: 4px;
	  width: 25px;
	  background: white;
	  position: absolute;
	  display: block;
	  content: ''; }
	#toggle span {
	  left: 10px;
	  top: 21px; }
	#toggle span:before {
	  top: -8px; }
	#toggle span:after {
	  bottom: -8px; }
	#toggle span, #toggle span:before, #toggle span:after {
	  transition: all 100ms ease-in-out;
	  -webkit-transition: all 100ms ease-in-out; }
	#toggle.active {
	  }
	#toggle.active span {
	  background-color: transparent; }
	#toggle.active span:before, #toggle.active span:after {
	  top: 0; }
	#toggle.active span:before {
	  transform: rotate(45deg);
	  -webkit-transform: rotate(45deg); }
	#toggle.active span:after {
	  transform: rotate(-45deg);
	  -webkit-transform: rotate(-45deg); }
	.post-left {width: 100%; float: none;}
	.post-box {width: 100%; float: none;}
	section.main.generic aside div.link.find-recipes, section.main.generic aside div.link.get-in-touch, .amazon-box {
		width: 236px;
		margin: 0 auto 10px;
		float: none;
		clear: both;
	}
	 div.trade-customers {
		width: 236px !important;
		margin: 0 auto 10px !important;
		float: none !important;
		clear: both !important;
	}
	.amazon-box div.trade-customers {width: 100% !important;}
	section.main.generic aside {margin: 0;}
	section.search form input[type="text"] {
	width: 100%;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	}
	section.search form input[type="submit"] {
	width: 100%;
	}
	div.container { width: 90%; }
	div.topbar div.inner-bar { width: 100%; }
	div.menu-button { right: 212px; }
	div.search-button { right: 10px; }
	section.menu div.columns > ul {
		width: 19.166666666666668%;
		margin-right: 5.416666666666667%;
	}

	/* Front page/product category */
	section.main.front-page ul.shelves,
	section.main.products ul.shelves {
		width: 97.5%;
		padding-left: -3.1914893617021276%;
	}
	.left-products {width: 50%;}


	section.main.front-page div.secondary,
	section.main.products div.secondary {
		width: 500px;
		height: 500px;
		margin: 0 auto;
	}

	section.main.front-page div.secondary div.chalkboard,
	section.main.products div.secondary div.chalkboard { left: -40px; }

	section.main.front-page div.secondary div.chalkboard h2,
	section.main.products div.secondary div.chalkboard h2 {
		width: auto;
		margin: 20px 20px 0 30px;
	}

	/* Generic */
	//section.main.generic article { width: 95% !img; }
	section.main.generic article div.video { width: 88%; }
	section.main.generic article img.full-width {
		width: 100%;
		height: auto;
	}

	/* Product */
	section.main.product article figure { width: 50%; }
	/* Bring back in at a future point: section.main.product article figure img { width: 100%; } */
	section.main.product article div.text { width: 49%; margin: 0; }

	/* Listings */
	section.main.listings{
		padding-top: 40px;

		p{
			font-size: 18px;
			line-height: 1.2;
		}
	}

	section.main.listings ul.listings { margin-left: 0; }
	section.main.listings ul.listings li { width: 100%; }
	section.main.listings ul.listings li div.text { width: 65%; }

	/* Footer */
	footer div.kitchen-utilities { display: none; }
	footer div.tablet-mobile-friendly {
		display: block;
		margin: 0 auto;
		width: 500px;
	}

	footer div.tablet-mobile-friendly div.drawers,
	footer div.tablet-mobile-friendly div.fridge { width: 500px; }
	footer div.tablet-mobile-friendly div.drawers {
		background: url('../images/footer-drawers.png');
		height: 578px;
	}

	footer div.tablet-mobile-friendly div.fridge {
		background: url('../images/footer-fridge.png');
		height: 577px;
	}

	footer div.small-print { width: 100%; }
}

@media screen and (max-width: 930px) {
	section.main.front-page ul.shelves li,
	section.main.products ul.shelves li { margin-left: 3%; }
}

@media screen and (max-width: 920px) {
	.mobile-menu h2.large {
		margin-top: 30px;
		font-size: 20px;
		line-height: 26px;
		margin: 45px 0 0 300px;
	}
	.title-holder {display: block; margin-top: 30px; width: 100%;}
	.bread {width: 100%; margin: -10px 0 0 0;}
}

@media screen and (max-width: 886px) {
	section.main.front-page ul.shelves li,
	section.main.products ul.shelves li { margin-left: 6%; }

}

@media screen and (max-width: 850px) {
	/* Generic */
	section.main.generic article { margin: 20px 0 0; }
	section.main.generic aside {
		width: 100% !important;
		margin-top: 0;
		margin-bottom: 20px;
	}

	section.main.generic aside div.link.find-recipes, section.main.generic aside div.link.get-in-touch, .amazon-box, section.main.generic aside div.link, section.main.generic aside div.trade-customers {
	width: 100% !important;
	margin: 10px auto 0 !important;
	float: none;
	clear: both;
	}

	section.main.generic aside div.link, section.main.generic aside div.trade-customers {
		background-position: 95% !important;
	}

	section.main.generic aside div.siblings {
		float: left;
		width: 52%;
		margin-left: 36px;
	}

	section.main.generic aside div.link,
	section.main.generic aside div.trade-customers {
		float: right;
		margin-right: 8px;
		margin-bottom: 0 !important;
		clear: right;
	}

	/* Product */
	section.main.product article figure {
		width: 96%;
		margin-bottom: 90px;
	}

	.left-products {width: 96%;}

	section.main.product article figure figcaption {
		width: 95%;
		padding-left: 0;
		padding-right: 0;
	}

	section.main.product article div.text {
		float: left;
		width: 96%;
	}
}

@media (max-width: 945px){

	#toggle {
		margin-top: -44px;
	}
}

@media (max-width: 578px){

	#toggle {
		margin-top: -53px;
	}
}

@media (max-width: 350px){

	#toggle {
		margin-top: -63px;
	}
}

@media screen and (max-width: 830px) {
	section.search form h2 { width: 100%; }
	header h2 {
		width: 60%;
		font-size: 18px;
	}

	header h2 span { font-size: 18px; }
	header h2 br { display: none; }
}

@media screen and (max-width: 792px) {
	section.main.front-page ul.shelves li,
	section.main.products ul.shelves li { margin-left: 10%; }
}

@media screen and (max-width: 768px) {
	section.main.generic article { width: 92%; }
	footer div.tablet-mobile-friendly {
		float: none;
		margin: 0 auto;
	}

	p > img {
	max-width: 95%;
	height: auto;
	}

	p > a > img {
	max-width: 95%;
	height: auto;
	}


	footer div.small-print {
		float: none;
		position: relative;
	}
}

@media screen and (max-width: 740px) {
	section.menu h2 { width: 50%; }
}

@media screen and (max-width: 710px) {
	div.menu-button, div.search-button { width: 25%; }
	div.menu-button { right: 28%; }
}

@media screen and (max-width: 700px) {
	.mobile-menu h2.large {
		margin: 45px 0 0 260px;
	}
	.title-holder {display: block; margin-top: 30px; width: 100%;}
	.bread {width: 100%; margin: -10px 0 0 0;}
}

@media screen and (max-width: 695px) {
	header h2 { width: 50%; }
	header h2,
	header h2 span {
		font-size: 16px;
		line-height: 24px;
	}

	section.search form { width: 100%; }
	section.search form input[type="text"] { width: 72%; }

	/* Front-page/Products */
	section.main.front-page ul.shelves,
	section.main.products ul.shelves { margin-top: 30px; }

	/* Generic */
	section.main.generic aside div.siblings {
		float: none;
		width: auto;
		margin-left: 0;
		margin-bottom: 8px;
		clear: both;
	}

	section.main.generic aside div.link,
	section.main.generic aside div.trade-customers {
		float: none;
		display: block;
		width: auto;
		margin: 0 auto 8px !important;
	}
}

@media screen and (max-width: 660px) {
	footer div.small-print { margin-top: 10px; }
	footer div.small-print p,
	footer div.small-print ul {
		float: none;
		height: 22px;
		margin: 0 auto;
	}
}



@media screen and (max-width: 650px) {
	section.menu div.columns > ul { width: 42%; }
	section.menu div.columns > ul:nth-of-type(3n) { clear: left; }

	/* Front-page/Products */
	section.main.front-page ul.shelves li,
	section.main.products ul.shelves li {
		margin-left: 16%;
	}

	/* Generic */
	section.main.page article div.content { padding-left: 0; }
	section.main.page article form input[type="text"],
	section.main.page article form input[type="email"] { width: 90%; }

	/* How to find us */
	section.main.how-to-find-us article ul.locations { margin-left: 0; }
	section.main.how-to-find-us article ul.locations li div.map { margin-bottom: 20px; }
	section.main.how-to-find-us article ul.locations li div.map,
	section.main.how-to-find-us article ul.locations li div.text {
		float: none;
		width: 90%;
	}
}

@media screen and (max-width: 610px) {
	section.search form input[type="text"] { width: 65%; }

	/* Product */
	section.main.product article { margin-bottom: 128px; }
	section.main.product article a.buy-now {
		margin-left: -98px;
		top: auto;
		bottom: -113px;
		left: 50%;
		right: auto;
	}

	/* Contact */
	section.main.contact article div.column-one,
	section.main.contact article div.column-two {
		float: none;
		width: 100%;
		margin: 0 0 20px;
	}

	section.main.contact article div.column-two input[type="text"],
	section.main.contact article div.column-two input[type="email"],
	section.main.contact article div.column-two textarea {
		width: 80%;

		@media (max-width: 1000px){
			width: 100%;
		}
	}
}

@media screen and (max-width: 900px) {

	.mobile-cols {
		width: 100%;
		max-width: 100%!important;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}
}



@media screen and (max-width: 580px) {
	header img.logo {
		top: 11px;
		//left: 50%;
		//margin-left: -80px;
		width: 160px;
	}
	.mobile-menu h2.large {
	margin: 55px 0 0 0;
	text-align: center;
	}
	.mobile-nav ul {padding: 0 !important;}
	.mobile-nav {padding: 25px 0 0;}
	header h2,
	header h2 span { font-size: 24px; }
	header h2 {
		width: 100%;
		padding-top: 187px;
		text-align: center;
	}

	.mobile-cols {
		width: 100%;
		max-width: 100%!important;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}
	.col-3 {
		clear: both;
	}

	header img.logo {
		z-index: 10;
	}

	section.menu h2 {
		width: 100%;
		margin-top: 40px;
		margin-left: 0;
		text-align: center;
	}

	/* Front-page/Products */
	//section.main.front-page ul.shelves,
	//section.main.products ul.shelves { margin-top: 140px; }
	section.main.front-page div.secondary,
	section.main.products div.secondary { display: none; }

	/* Generic */
	section.main.generic article div.video { width: 82%; }
}

@media screen and (max-width: 550px) {
	footer div.tablet-mobile-friendly { display: none; }
}

@media screen and (max-width: 500px) {
	section.main.generic article div.content img {
		width: 100%;
		height: auto;
	}

	section.main.contact article div.column-one ul.non-float-social-icons li img {
		width: auto;
		height: auto;
	}
}

@media screen and (max-width: 528px) {
	/* Front-page/Products */
	section.main.front-page ul.shelves,
	//section.main.products ul.shelves { margin-top: 160px; }

	/* Listings */
	//section.main.listings ul.listings li figure { width: 30%; }
	section.main.listings ul.listings li figure img { width: 100%; }
	section.main.listings ul.listings li div.text { width: 50%; }
}

@media screen and (max-width: 492px) {
	section.search form input[type="text"] { width: 55%; }
}

@media screen and (max-width: 470px) {
	section.menu div.columns > ul {
		float: none;
		width: 100%;
		margin-right: 0;
	}
	.mobile-cols {
		float: none;
		width: 100%;
		max-width: 100%!important;
	}
}

@media screen and (max-width: 464px) {
	footer div.small-print ul li:nth-of-type(2n) { border-right: none; }
	footer div.small-print ul li:last-of-type {
		margin : 6px 0 10px;
		line-height: 18px;
	}
}

@media screen and (max-width: 450px) {
	section.search form input[type="text"] { width: 50%; }
	div.menu-button, div.search-button { width: 30%; }
	div.menu-button { right: 35%; }
	section.main.front-page ul.shelves,
	section.main.products ul.shelves {
		width: 100%;
		//margin-left: -6%;
		padding: 0 6%;
	}

	section.main.front-page ul.shelves li,
	section.main.products ul.shelves li {
		float: none;
		margin: 0 auto;
	}
}

@media screen and (max-width: 350px) {
	section.search form input[type="text"] { width: 40%; }

	/* Front-page/Products */
	section.main.front-page ul.shelves,
	section.main.products ul.shelves { margin-top: 180px; }
}

@media screen and (max-width: 330px) {
	section.main.product article figure figcaption { width: 90%; }
}
.grecaptcha-badge {
	opacity: 0;
}
#wpcf7-f5532-p5533-o1 .wpcf7-list-item-label, #wpcf7-f5532-p5533-o1 .wpcf7-form p {
	color: #fff;
}
.back-list {
   padding-bottom: 76px;
   top: -76px;
}

.with-aside{
	padding: 0;
	padding-top: 80px;

	@media (max-width: 900px){
		padding-top: 0;
	}

	.bread{
		margin-top: -76px!important;
		margin-bottom: 96px!important;
		position: relative;

		@media (max-width: 900px){
			margin-top: 0px!important;
			margin-bottom: 10px!important;
		}
	}

	.col-md-4{

		@media (min-width: $b3){
			flex: 0 0 20%;
			max-width: 20%;

			&.img-col{
				flex: 0 0 40%;
				max-width: 40%;
			}
		}

		@media (max-width: 1100px){

			&.img-col{
				flex: 0 0 28%;
				max-width: 28%;
			}
		}

		@media (max-width: 750px){

			&.img-col{
				display: none;
			}
		}
	}

	.img-col{
		padding-top: 50px;

		img{
			margin: 0 auto;
			display: block;
			max-width: 100%;

			+ img{
				box-shadow: 9px 20px 10px #000000c2;
			}
		}
	}

	.boo{
		background-color: red;
	}

	.col-md-8{

		@media (min-width: $b3){
			flex: 0 0 80%;
			max-width: 80%;

			&.product-side{
				flex: 0 0 60%;
				max-width: 60%;
			}
		}

		@media (max-width: 1100px){

			&.product-side{
				flex: 0 0 72%;
				max-width: 72%;
			}
		}

		@media (max-width: 750px){

			&.product-side{
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}

	.navigation.navigation{
		padding: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 9px 0 9px;

		.wp-paginate{
			display: flex;
			justify-content: center;
		}

		li{
			@include font-size(24);
			line-height: 30px;
			margin: 0 5px;

			&:nth-last-of-type(2){

				.page{
					margin: 0;

					&:after{
						display: none;
					}
				}
			}

			&:nth-last-of-type(1){

				.page{
					margin: 0 0 0 18px;

					&:after{
						right: auto;
						left: -18px;
					}
				}
			}
		}

		.page{
			padding: 0;
			margin: 0 auto;
			@include font-size(24);
			line-height: 30px;
			font-weight: 700;
			position: relative;
			margin: 0 18px 0 0;

			&.current{
				margin: 0 18px 0 0;
			}

			&:after{
				content: '';
				width: 6px;
				height: 6px;
				background-color: white;
				border-radius: 6px;
				position: absolute;
				top: 50%;
				right: -18px;
			}

		}

		.next,
		.prev{
			@include font-size(0);
			width: 30px;
			height: 30px;
			display: block;
			background-color: $purple!important;
			border-radius: 30px;
			padding: 0;
			margin: 0;
			background-image: url(../images/button-arrow.svg);
			background-position: 55% 50%;
			background-repeat: no-repeat;
		}

		.prev{
			background-position: 45% 50%;
			background-image: url(../images/button-arrow-left.svg);
		}
	}
}

.btn{
	border: 2px solid white;
	display: inline-block;
	@include font-size(16);
	line-height: 1.2em;
	background-image: url(../images/button-arrow.svg);
	background-repeat: no-repeat;
	background-position: top 50% right 13px;
	background-color: $purple;
	color: white;
	font-weight: 700;
	text-transform: uppercase;
	border-radius: 20px;
	padding: 8px 35px 8px 17px;
	text-align: left;
	text-decoration: none;

	&.green{
		background-color: $green;

		&:hover,
		&:focus{
			background-color: $purple;
			color: white;
		}
	}

	&:hover,
	&:focus{
		background-color: $green;
		color: white;
	}
}

.cat-aside{

	@media (max-width: $b3){
		order: 1;
		margin-top: 2em;
	}

	h3{
		@include font-size(18);
		@include line-height(18,21.6);
		margin-bottom: .3em;
	}

	p{
		@include font-size(14);
		@include line-height(14,16.8);
		margin-bottom: 1em;
	}
}

.searchandfilter.searchandfilter.searchandfilter{

	ul{
		list-style: none;
		margin: 0;

		li{
			padding: 0;
			margin-bottom: 10px;

			ul ul{
				padding: 0 15px;

				li{
					margin-bottom: 0;

					&:first-of-type{

						.sf-input-checkbox{

							+ .sf-label-checkbox{
								border-top: none;
								padding: 11px 33px 2px 14px;

								&:before{
									top: calc(50% + 5px);
								}
							}
						}
					}

					&:last-of-type{

						.sf-input-checkbox{

							+ .sf-label-checkbox{
								border-bottom: 2px solid white;
								border-radius: 0 0 14px 14px;
								padding: 2px 33px 11px 14px;

								&:before{
									top: calc(50% - 5px);
								}
							}
						}
					}

					.sf-input-checkbox{

						+ .sf-label-checkbox{
							border-radius: 0;
							background-color: $purple;
							border-bottom: none;
							padding: 2px 33px 2px 14px;
							color: white;

							&:before{
								background-size: auto 16px;
								right: 11px;
								width: 16px;
								height: 16px;
							}
						}
					}
				}
			}
		}
	}

	> ul{

		> li{


			.sf-input-checkbox{
				display: none;

				&:checked{

					+ .sf-label-checkbox{

						&:before{
							background-position: 100% 50%;
						}
					}
				}

				+ .sf-label-checkbox{
					border: 2px solid white;
					display: block;
					@include font-size(16);
					line-height: 1.2em;
					background-color: $purple-lighter;
					color: #231f20;
					font-weight: 700;
					text-transform: uppercase;
					border-radius: 20px;
					padding: 9px 33px 9px 14px;
					text-align: left;
					text-decoration: none;
					letter-spacing: -.035em;
					position: relative;
					cursor: pointer;

					@media (max-width: 950px){
						@include font-size(13);
					}

					&:before{
						content: '';
						position: absolute;
						top: 50%;
						right: 10px;
						width: 20px;
						height: 20px;
						background-image: url(../images/checkbox.svg);
						background-repeat: no-repeat;
						transform: translateY(-50%);
						background-position: 0 50%;
						background-size: auto 20px;
					}
				}
			}
		}
	}
}

.recipes-list{
	clear: both;
	padding: 0;

	.recipes-item{
		margin: 0 0 38px;
		width: 25%;
		flex: 0 0 25%;
		text-align: center;

		@media (max-width: 1050px){
			width: 33.3%;
			flex: 0 0 33.3%;
		}

		@media (max-width: 700px){
			width: 50%;
			flex: 0 0 50%;
		}

		@media (max-width: 375px){
			width: 100%;
			flex: 0 0 100%;
		}

		.inner{
			display: flex;
			flex-direction: column;
			height: 100%;
		}

		.img-con{
			margin-bottom: .4em;
			position: relative;
			flex: 0 0 auto;

			a{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 100;
			}

			img{
				max-width: 100%;
				width: 100%;
				border-radius: 30px;
			}
		}

		.text-con{
			flex: 1 1 auto;


			h3{
				@include font-size(22);
				@include line-height(24,28.8);
				text-transform: uppercase;
				font-weight: 900;

				@media (max-width: 900px){
					@include font-size(16);
				}
			}

			p{
				line-height: 1.3em;
				font-size: 15px;
			}
		}

		.btn-con{

			.btn{
				width: 100%;
			}
		}
	}
}

.recipe-content{
	padding-top: 30px;
	font-size: 0;

	.blog-img{
		border-radius: 30px;
		margin-bottom: 1.15em;
	}

	.recipe-image{
		border-radius: 28px;
		margin-bottom: 1.45em;
		display: inline-block;
		width: 46%;
		vertical-align: top;
		padding-right: 4%;
		margin-bottom: 20px;
		@media(max-width:500px){
			display: block;
			width: 100%;
		}
	}

	h1{
		@include font-size(26);
		@include line-height(26,31.2);
		text-transform: uppercase;
		margin-bottom: 0.6em;
		font-weight: 900;
	}
	&--wrapper {
		display: inline-block;
		width: 46%;
		vertical-align: top;
		padding-left: 4%;
		p {
			line-height: 1.6;
		}
		@media(max-width:500px){
			display: block;
			width: 100%;
		}
	}

	li,
	p{
		@include font-size(18);
		@include line-height(18,21.6);

		a{
			font-size: inherit;
		}
	}

	h4{
		@include font-size(18);
		@include line-height(18,21.6);
		text-transform: uppercase;
		margin-top: 1.4em;
	}

	p{

		.blog-side &{
			line-height: 1.75em;
		}
	}

	hr{
		max-width: 100%;
		width: 286px;
		margin-left: 0;
		margin-right: 0;
	}

	h3{
		@include font-size(24);
		@include line-height(24,28.8);

		&:last-child{
			margin-bottom: -0.95em;
		}
	}

	.under{
		padding-top: 25px;

		h4{
			margin-top: 0;
			margin-bottom: .5em;
		}

		h2{
			text-transform: uppercase;
			@include font-size(18);
			@include line-height(18,21.6);
		}

		p{
			@include line-height(18,21.6);
			margin-bottom: .6em;
		}
	}
}

.accordion-multi{
	margin-top: -2px;
	margin-top: 20px;

	.btn{
		display: block;
	}

	dt{
		border: 2px solid white;
		display: block;
		@include font-size(16);
		line-height: 1.2em;
		background-image: url(../images/button-arrow-black.svg);
		background-repeat: no-repeat;
		background-position: top 50% right 13px;
		color: #231f20;
		font-weight: 900;
		text-transform: uppercase;
		border-radius: 20px;
		padding: 8px 35px 8px 17px;
		text-align: left;
		text-decoration: none;
		background-color: #e4d6de;
		cursor: pointer;

		span{
			@include font-size(12);
			@include line-height(12,14.4);
		}

		+ dd{
			max-height: 0;
			overflow: hidden;
			margin-bottom: 9px;
		}

		&:hover{
			background-color: $purple;
			color: white;
		}

		&.open{
			background-image: url(../images/button-arrow-down.svg);
			background-color: $purple;
			color: white;

			+ dd{
				max-height: 9999px;
			}
		}

		&:hover{
			background-color: $purple;
		}
	}

	dd{

		.inner{
			padding: 21px 17px 23px;

			p{

				strong{
					color: white;
					display: inline-block;
					margin-top: 1em;
				}
			}

			&.column{

				@media (min-width: $b3 + 1){
					padding-right: 158px;
				}

				ul{
					list-style: none;

					li{
						break-inside: avoid;

						&:before{
							content: '•';
							margin-right: 3px;
						}
					}


					@media (min-width: $b3 + 1){
						column-count: 2;
						column-gap: 27px;
					}
				}
			}

			&.text{
				padding-bottom: 30px;
			}

			&.video{
				display: flex;
				align-items: center;
				justify-content: center;
				padding-top: 25px;
				padding-bottom: 28px;

				a{
					display: inline-block;
					font-size: 0;
					position: relative;
					border-radius: 12px;
					overflow: hidden;

					&:before{
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba(white, .05);
					}

					&:after{
						content: '';
						width: 52px;
						height: 52px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						background-image: url(../images/button-arrow.svg);
						background-repeat: no-repeat;
						background-size: 24.378px 29.579px;
						border-radius: 52px;
						background-color: black;
						background-position: 55% 50%;
					}
				}


			}

			li{
				margin-bottom: .35em;
			}

			ol,
			ul{
				margin-left: 0;
			}

			ol{
				counter-reset: section;

				li{
					list-style: none;
					margin-bottom: .29em;

					&:before {
						counter-increment: section;
						content: counter(section)".";
						margin-right: 22px;
					}
				}
			}

			ol,
			ul{

				&:last-of-type{
					margin-bottom: 0;
				}

				li{

					&:last-of-type{
						margin-bottom: 0;
					}
				}
			}

			table{
				height:1px!important;
				margin: 7px 9px;
				max-width: calc(100% - 18px);
				@include font-size(15);
				margin-bottom: 1.3em;

//				*{
//					height:1px!important;
//				}

				tbody{

					tr{
						position: relative;

						&:nth-of-type(odd){
							background-color: #9f5f85;
						}

						&:last-of-type{

							td{

								&:before{
									display: none;
								}
							}
						}

						td{
							border: none!important;
							position: relative;
							padding: 2px 7px;
							width: auto!important;

							&:after{
								border-collapse: collapse;
								content: '';
								width: 1px;
								height: 100%;
								position: absolute;
								top: 0;
								right: 0;
								display: block;
								background-color: white;
							}

							&:before{
								border-collapse: collapse;
								content: '';
								width: 100%;
								height: 1px;
								position: absolute;
								bottom: 0;
								right: 0;
								display: block;
								background-color: white;
							}

							&:last-of-type{

								&:after{
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

.related-title{
	padding-top: 22px;
	padding-bottom: 12px;

	h2{
		@include font-size(20);
		@include line-height(20,24);
	}

	+ .recipes-list{
		margin-bottom: -44px;

		@media (max-width: $b3){
			margin-bottom: 0;
		}
	}
}

.share-links{
	padding-top: 29px;
	padding-bottom: 29px;

	display: flex;
	align-items: center;

	span{
		@include font-size(16);
		@include line-height(16,19.2);
		margin-right: 16px;
		display: inline-block;
	}

	a{
		@include font-size(27);
		margin-right: 10px;
		color: white;

		&:hover,
		&:focus{
			color: $green;
		}
	}
}

.share-links + .more{
	@include font-size(25);
	@include line-height(25,30);
	color: white;
	font-weight: 900;
	text-transform: uppercase;

	i{
		background-image: url(../images/button-arrow.svg);
		background-repeat: no-repeat;
		background-size: 20.278px 24.603px;
		width: 20.278px;
		height: 24.603px;
		display: inline-block;
		margin-bottom: -0.13em;
		margin-left: 0.2em;
	}

	&:hover,
	&:focus{

		span{
			color: $purple;
		}
	}

	span{
		text-decoration: underline;
	}

}

.under{

	p{

		a:not(.btn){
			text-decoration: underline;
			color: white;

			&:hover,
			&:focus{
				color: $purple;
			}
		}
	}

	.btn{
		width: calc(33% - 4px);
	}

	.btn{
		background-color: $purple-lighter;
		min-width: 200px;
		margin-right: 10px;
		color: #231f20;
		background-image: url(../images/button-arrow-black.svg);
		background-repeat: no-repeat;
		margin-top: .5em;
		margin-bottom: .5em;

		+ .btn:last-of-type{
			margin-right: 0;
		}

		&:hover,
		&:focus{
			background-image: url(../images/button-arrow.svg);
			background-repeat: no-repeat;
			background-color: $purple;
			color: white;
		}
	}
}

.under-slide-arrow{
	position: relative;
	transform: translateY(-81px);

	.next-secction{
		width: 46.6px;
		height: 46.6px;
		border-radius: 51.6px;
		background-image: url(../images/button-arrow-down.svg);
		background-position: 50% 55%;
		background-repeat: no-repeat;
		background-size: 24.408px 20.116px;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 50%);
		z-index: 1;
		background-color: $purple;
		border: 3px solid white;
	}
}

.banner-month{
	background-color: white;
	position: relative;
	margin-bottom: 81px;

	&:before{
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 100vw;
		transform: translateX(-50%);
		height: calc(100% + 172px);
		background-color: white;
		z-index: -1;
	}

	.container-g{
		padding-bottom: 15px;
		padding-top: 54px;

		@media (max-width: 1000px){
			padding-top: 0;
			padding-bottom: 30px;
		}

		.text-side{

//			@media (max-width: 580px){
//				text-align: center;
//			}
		}

		.row{
			height: 320px;

			@media (max-width: 767px){
				height: auto;
			}

			.text-side{

				@media (min-width: 1000px + 1){
					padding-left: 43px;
					padding-right: 43px;
				}
			}

			.img-side{
				position: relative;

				@media (max-width: 767px){
					display: none;
				}

				img{
					position: absolute;
					bottom: 1px;
					right: 1px;
					max-width: 109.4%;
					max-height: 166.2%;

					@media (max-width: 1000px){
						max-width: 100%;
						max-height: 100%;
					}
				}
			}
		}

		.rotm{
			background-color: $green;
			color: white;
			@include font-size(16);
			line-height: 1.2em;
			padding: 7.5px 20px 7.5px;
			border-radius: 30px;
			display: inline-block;
			font-weight: 700;
			margin-bottom: .6em;
		}

		h1{
			color: $purple;
			@include font-size(45);
			line-height: 1em;
			text-transform: uppercase;
			font-weight: 900;
			padding-right: 70px;
			margin-bottom: .36em;

			@media (max-width: 1000px){
				padding-right: 0;
				@include font-size(30);
			}
		}

		p{
			color: #231f20;
			@include font-size(16);
			@include line-height(16,19.2);
			padding-right: 80px;

			@media (max-width: 1000px){
				padding-right: 0;
				@include font-size(14);
			}
		}

		.r-btn{
			background-color: $purple;
			border-radius: 30px;
			padding: 5px 29px 5px;
			display: inline-block;
			@include font-size(16);
			font-weight: 700;
			color: white;

			&:hover,
			&:focus{
				background-color: $green;
			}
		}
	}
}

.home-under{
	padding-top: 37px;
	padding-bottom: 2px;

	.text{
		margin: 0 auto;
		width: 700px;
		max-width: 100%;
		flex: 0 0 700px;
	}

	h1{
		@include font-size(30);
		@include line-height(30,36);
		margin-bottom: -0.2em;
		font-weight: 900;
	}

	h2{
		@include font-size(33);
		@include line-height(33,39.6);
		font-family: $alt-font2;
		font-weight: 400;
		margin-bottom: 0.54em;
	}

	p{
		@include font-size(16);
		@include line-height(16,19.2);
		margin-bottom: .7em;
	}

	hr{
		border-top: none;
		width: 150px;
		max-width: 100%;
		margin: 0 auto 1.6em;

		+ p{

			strong{
				@include font-size(18);
				@include line-height(18,21.6);
			}
		}
	}
}

.product-side{
	display: flex;
	flex-wrap: wrap;

	.mob-images{

		@media (min-width: 750px + 1){
			display: none;
		}

		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 auto 1.5em;
	}

	.bread{
		width: 100%;
	}

	.product-info-col,
	.buy-col{
		margin-top: -14px;

		@media (max-width: 900px){
			margin-top: 0;
		}


		li,
		p{
			@include font-size(16);
			@include line-height(16,19.2);
		}
	}

	.product-info-col.product-info-col{
		width: calc(100% - 218px);
		padding-right: 22px;

		@media (max-width: 900px){
			width: 100%;
			padding-left: 0;
			padding-right: 0;
		}

		h3{
			@include font-size(30);
			@include line-height(30,36);
			text-transform: uppercase;
			margin-bottom: 0.32em;
			font-weight: 900;

			+ p{
				margin-bottom: .8em;
			}
		}

		img{
			margin-right: 11px;
		}

		.btn{
			float: right;
			margin-top: 12px;
			margin-bottom: 12px;
			border: 2px solid $green;
			background-color: $green;

			@media (max-width: 1100px){
				float: none;
				width: 100%;
			}

			&:hover,
			&:focus{
				border: 2px solid $purple;
				background-color: $purple;
			}
		}

		ol{
			list-style-position: outside;
			margin-left: 9px;

			li{
				list-style: decimal;

				&:before{
					display: none;
				}
			}
		}

		.accordion-multi{

			dd{

				.inner{
					padding: 11px 7px 8px 17px;

					&.text{

						p{

							&:last-child{
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}

	.buy-col{
		width: 218px;

		@media (max-width: 900px){
			margin: 2em auto 0;
			width: 100%;

			.link,
			.amazon-box{
				width: 100%!important;
			}
		}

		.link.get-in-touch.get-in-touch,
		.link.find-recipes.find-recipes{
			background-image: none;
			background-color: transparent;
			line-height: normal;
			height: auto;
			margin-bottom: 11px;

			a{
				display: block;
				line-height: 1.2em;
				background-color: $green;
				border: 2px solid white;
				border-radius: 15px;
				padding: 12px 30px 12px 12px;
				@include font-size(16);
				@include line-height(16,19.2);
				text-transform: uppercase;
				background-image: url(../images/button-arrow.svg);
				background-position: right 15px top 50%;
				background-repeat: no-repeat;
				font-weight: 700;

				&:hover,
				&:focus{
					background-color: $purple;
				}
			}
		}

		.link.get-in-touch.get-in-touch{

			a{
				background-color: $purple;

				&:hover,
				&:focus{
					background-color: $green;
				}
			}
		}

		.amazon-box{
			margin-top: 0;
			border-radius: 13px;
			padding: 20px 12px;

			.inners{
				border-bottom: none;
			}

			.trade-customers{
				background-image: none;padding-top: 1em;
			}

			p{
				@include font-size(18);
				@include line-height(18,21.6);
				font-weight: 900;
				text-transform: uppercase;
				margin-bottom: .7em;
			}

			img{
				margin-bottom: .4em;
			}

			.o-title{
				color: #000;
				@include font-size(14);
				@include line-height(14,16.8);
				margin-bottom: .9em;
			}

			.btn{
				display: block;
				padding: 10px 35px 10px 17px;
				text-align: center;
			}

			.trade-customers{
				background-image: none;
				padding-top: 0.6em;

				a{
					@include font-size(18);
					@include line-height(18,21.6);

					span{
						@include font-size(18);
						@include line-height(18,21.6);
					}
				}
			}
		}
	}

	.accordion-multi.accordion-multi.accordion-multi table{
		border: 1px solid black;
		color: black;
		font-weight: 400;

		td,
		tr{
			border: 1px solid black!important;

			&:before,
			&:after{
				display: none;
			}
		}

		tr{
			background-color: white;

			&:nth-of-type(even){
				background-color: #e4d6de;
			}

		}
	}
}

.related-aside{
	background-color: $purple-dark;
	border-radius: 13px;
	padding: 15px 12px;
	margin-top: 20px;
	text-align: center;
	margin-bottom: 6px;

	h3{
		@include font-size(19);
		@include line-height(19,22.8);
		font-weight: 900;
		text-transform: uppercase;
		margin-bottom: 0;
	}

	h2{
		@include font-size(25);
		line-height: 1.1em;
		font-weight: 900;
		text-transform: uppercase;
		margin-bottom: 1em
	}

	img{
		display: block;
		margin: 0 auto;
	}

	.btn{
		border-color: $purple;
		background-color: $purple;
		border-radius: 15px;
		width: 97%;

		&:hover,
		&:focus{
			border-color: $green;
			background-color: $green;
		}
	}
}

.main.product{

	ul.shelves{

		@media (max-width: 600px){
			margin-top: 0!important;
		}
	}
}

.home-month-slide.home-month-slide{
	display: block;

	.slick-list{
		overflow: visible;
	}
}

.home-month-slide{

	&:before,
	&:after{
		content: '';
		position: absolute;
		bottom: 81px;
		height: 100vh;
		width: 100vw;
		background-color: white;
		z-index: 1;
	}

	&:before{
		right: 100%;
	}

	&:after{
		left: 100%;
	}
}

html{
	overflow-x: hidden;

	body{
		overflow: hidden;
	}
}

.main.products{
	padding-top: 20px;
}


.search-results.search-results.search-results.search-results{

	.main{

		.title{
			@include font-size(48);
			margin-bottom: 40px !important;
			line-height: 1.2 !important;

			@media (max-width: 767px){
				@include font-size(28);
			}
		}

		.listings{
			margin: 50px auto;

			li{
				border-bottom: 1px solid white;
				margin-bottom: 16px;
				padding-bottom: 16px;

				&:last-of-type{
					border-bottom: none;
				}
			}

			h4{
				color: white;
				@include font-size(22);

				a{
					color: white;

					&:hover,
					&:focus{
						text-decoration: underline
					}
				}
			}

		}
	}
}

.product-slide-con{

	.shelf-img{
		margin: 0 auto;
		display: block;
		box-shadow: 9px 20px 10px #000000c2;
	}
}

.product-slide{

	.slick-track{
		display: flex;
		align-items: flex-end;

		img{
			max-width: 100%;
		}
	}

	img{
		margin: 0 auto;
	}
}


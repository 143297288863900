@font-face {
	font-family: 'Brandon Grotesque';
	src: url('../fonts/BrandonGrotesque-Light.woff2') format('woff2'),
		url('../fonts/BrandonGrotesque-Light.woff') format('woff'),
		url('../fonts/BrandonGrotesque-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Brandon Grotesque';
	src: url('../fonts/BrandonGrotesque-Medium.woff2') format('woff2'),
		url('../fonts/BrandonGrotesque-Medium.woff') format('woff'),
		url('../fonts/BrandonGrotesque-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Brandon Grotesque';
	src: url('../fonts/BrandonGrotesque-Bold.woff2') format('woff2'),
		url('../fonts/BrandonGrotesque-Bold.woff') format('woff'),
		url('../fonts/BrandonGrotesque-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Brandon Grotesque';
	src: url('../fonts/BrandonGrotesque-Black.woff2') format('woff2'),
		url('../fonts/BrandonGrotesque-Black.woff') format('woff'),
		url('../fonts/BrandonGrotesque-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Yellowtail';
	src: url('../fonts/Yellowtail.woff2') format('woff2'),
		url('../fonts/Yellowtail.woff') format('woff'),
		url('../fonts/Yellowtail.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

//BREAK POINTS
$b1:				1140px - 12px - 1px; //$base-width + $grid-gutter-width - 1px
$b2:				992px - 1px; //992px - 1px
$b3:				768px - 1px; //768px - 1px
$b4:				576px - 1px; //576px - 1px
$b5:				450px; //450px

//xs: 0,
//sm: 576px,
//md: 768px,
//lg: 992px,
//xl: $base-width

//!!COLOURS
$red:				#ac0000;

$green:				#48a088;

$grey:				#939598;

$purple-lighter:	#e4d6de;
$purple-light:		#9f5f85;
$purple:			#8f3d71;
$purple-dark:		#6d2655;

$white:				#fff;
$black:				#000;

$colour: 			$black;
$main-bg-colour:	$white;

$font-brand:		'Brandon Grotesque', sans-serif;
//light - 300
//medium - 500
//bold - 700
//black - 900

$font-yellow:		'Yellowtail', sans-serif;
//normal - 500

$main-font:			$font-brand;
$alt-font:			$font-brand;
$alt-font2:			$font-yellow;

//!!GRID SETTINGS
$grid-columns:		12 !default;
$grid-gutter-width:	12px !default;

//!!PAGE WIDTH
$base-width:		1140px;

//!!SITE TRANSITIONS
$time:				.2s;

//!!FONT SIZE DIVIDER
$divider:			1.05;
$divider-big:		1.7;

footer{

	.container-g{
		padding-right: 33px;
		padding-left: 33px;

		.row{
			margin-right: -33px;
			margin-left: -33px;
		}

		.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
			padding-right: 33px;
			padding-left: 33px;
		}
	}

	.footer-top{
		background-color: $purple;
		text-align: center;
		padding-top: 10px;
		padding-bottom: 10px;

		a{
			@include font-size(26);
			color: white;
			text-transform: uppercase;
			font-weight: 900;

			@media (max-width: 600px){
				font-size: 15px
			}

			span,
			i{
				display: inline-block;
				vertical-align: middle;
			}

			i{
				background-image: url(../images/button-arrow.svg);
				background-repeat: no-repeat;
				width: 20.278px;
				height: 24.598px;
				background-size: 20.278px 24.598px;
				margin-left: 25px;

				@media (max-width: 600px){
					display: none;
				}
			}
		}
	}

	.footer-middle{
		padding-top: 31px;
		padding-bottom: 31px;
		background-color: black;

		h2{
			@include font-size(24);
			font-weight: 900;
			@include line-height(24,28.8);
			text-transform: uppercase;
			margin-bottom: .1em;
		}

		.sign-up-col{

			@media (max-width:$b3){
				margin-bottom: 2em;
			}

			h2{
				margin-bottom: .8em;
			}

			.sign-btn{
				border-radius: 10px;
				background-color: $purple;
				@include font-size(18);
				font-weight: 700;
				display: block;
				text-align: center;
				color: white;
				text-transform: uppercase;
				padding: 12.5px 10px 12.5px 10px;

				&:hover,
				&:focus{
					background-color: $green;
					color: white;
				}

				span,
				i{
					display: inline-block;
					vertical-align: middle;
				}

				i{
					background-image: url(../images/button-arrow.svg);
					background-repeat: no-repeat;
					width: 14.081px;
					height: 17.087px;
					background-size: 14.081px 17.087px;
					margin-left: 10px;
				}
			}
		}

		.twitter-col{

			@media (max-width:$b3){
				margin-bottom: 2em;
			}

			p{
				margin-bottom: 1.8em;

				a{
					color: white;
				}
			}

			ul{
				margin: 0;

				li{
					list-style: none;
					@include font-size(14);
					@include line-height(14,16.18);
					font-weight: 400;
					margin-bottom: 1.9em;

					.top{
						font-weight: 500;
					}
				}
			}
		}

		.instagram-col{

			@media (max-width:$b3){
				margin-bottom: 2em;
			}

			p{
				margin-bottom: 1.8em;

				a{
					color: white;
				}
			}
		}

		.follow-us-col{

			.social-links{
				padding-top: 6px;
				display: flex;
				justify-content: space-between;

				a{
					@include font-size(38);
					color: white;

					&:hover,
					&:focus{
						color: $green;
					}
				}
			}
		}
	}

	.instagram-images{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.i-item{
			width: 47%;
			@include font-size(0);
			margin-bottom: 13px;

			.immg-con{
				padding-top: 100%;
				position: relative;
				background-position: 50% 50%;
				background-size: cover;

				a{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.footer-bottom{
		background-color: white;
		padding-top: 17px;
		padding-bottom: 20px;

		.col-12{
			display: flex;
			justify-content: space-between;
			align-items: center;

			@media (max-width: 910px){
				flex-direction: column;
			}
		}

		img.logo {
			width: 212px;
			height: auto;

			@media (max-width: 910px){
				width: 150px;
				margin-bottom: 1em;
			}
		}

		.foot-nav{
			flex: 1 1 auto;
			padding-right: 19px;
			padding-left: 19px;

			@media (max-width: 910px){
				margin-bottom: 1em;
				justify-content: center;
				text-align: center;
			}

			li{

				@media (max-width: 910px){
					margin: 0 10px 5px;
				}

				a{
					color: #231f20;
					@include font-size(14);
					margin: 0 22px 0 0;

					@media (max-width: 910px){
						margin: 0;
					}
				}
			}
		}

		.by-con{
			text-align: right;

			@media (max-width: 910px){
				text-align: center;
			}

			*{
				color: #231f20;
				@include font-size(10);
				@include line-height(10,12);
			}
		}
	}

}

